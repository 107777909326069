package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_305_4 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.emissions?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_4
        }

        RomanListWrapper(
            title = "When compiling the information specified in Disclosure 305-1, the reporting organization shall:",
            list = listOf(
                "exclude any GHG trades from the calculation of gross direct (Scope 1) GHG 2.1.2 emissions;",

                "report biogenic emissions of CO2 from the combustion or biodegradation of biomass separately from the" +
                    " gross direct (Scope 1) GHG emissions. Exclude biogenic emissions of other types of GHG (such as" +
                    " CH4 and N2O), and biogenic emissions of CO2 that occur in the life cycle of biomass other " +
                    "than from combustion or biodegradation (such as GHG emissions from processing or " +
                    "transporting biomass).",
            ),
            inClassName = ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        Box{
            className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass

            listOf("","Unit", "Value").forEach {
                TableHeader {
                    header = it
                }
            }

            label {
               +"GHG emissions intensity ratio for the organization."
            }
            ESGTextFieldWrapper{
                name = state::section3054Unit3.name
                value = state.section3054Unit3
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3054Current3.name
                value = state.section3054Current3
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

            label {
                +"Absolute GHG emissions (the numerator)"
            }
            ESGTextFieldWrapper{
                name = state::section3054Unit1.name
                value = state.section3054Unit1
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3054Current1.name
                value = state.section3054Current1
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

            label {
                +"Organization-specific metric (the denominator) chosen to calculate the ratio."
            }
            ESGTextFieldWrapper{
                name = state::section3054Unit2.name
                value = state.section3054Unit2
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3054Current2.name
                value = state.section3054Current2
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "Types of GHG emissions included in the intensity ratio; whether direct (Scope 1), energy" +
                        "indirect (Scope 2), and/or other indirect (Scope 3)",
                    state::section3054Field1.name,
                    state.section3054Field1
                ),
                TextAreaFieldMapper(
                    "Gases included in the calculation; whether CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, or all",
                    state::section3054Field2.name,
                    state.section3054Field2
                ),
            ),
            props
        )

    }
}

