package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_305_5 = FC<EsgSubFormComponentProps> { props ->
    props.esgReportState.report?.emissions?.let { state ->
        val listName = state::breakdownOfGHGEmissionOnScopeTypeList.name
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_5
        }

        RomanListWrapper(
            title = "When compiling the information specified in Disclosure 305-5, the reporting organization shall:",
            list = listOf(
                "exclude reductions resulting from reduced production capacity or outsourcing;",
                "use the inventory or project method to account for reductions;",
                "calculate an initiative’s total reductions of GHG emissions as the sum of its associated primary " +
                    "effects and any significant secondary effects;",
                "if reporting two or more Scope types, report the reductions for each separately;",
                "report reductions from offsets separately."
            ),
            inClassName = ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        ESGBaseFormBuilder(
            listOf(
                DecimalFieldMapper(
                    "GHG emissions reduced as a direct result of reduction initiatives, in metric tons of CO2 " +
                        "equivalent.",
                    state::section3055Current1.name,
                    state.section3055Current1
                ),
                TextAreaFieldMapper(
                    "Gases included in the calculation; whether CO2, CH4, N2, HFCs, PFCs, SF6, NF3, or all",
                    state::section3055Current2.name,
                    state.section3055Current2
                ),
                TextAreaFieldMapper(
                    "Base year or baseline, including the rationale for choosing it",
                    state::section3055Field1.name,
                    state.section3055Field1
                ),
                TextAreaFieldMapper(
                    "Scopes in which reductions took place; whether direct (Scope 1), energy indirect " +
                        "(Scope 2), and/or other indirect (Scope 3).",
                    state::section3055Field2.name,
                    state.section3055Field2
                ),
                TextAreaFieldMapper(
                    "Standards, methodologies, assumptions, and/or calculation tools used",
                    state::section3055Field3.name,
                    state.section3055Field3
                ),
            ),
            props
        )

        DisclosureSubHeading{
            subHeading = "If reporting two or more Scope types, report the reductions for each separately."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(listName) }
                items = state.breakdownOfGHGEmissionOnScopeTypeList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                    val headers = listOf(
                        "Scope Type",
                        "GHG emissions reduced (metric tons of CO2 equivalent) per Scope"
                    )
                    for (inHeader in headers) {
                        TableHeader {
                            header = inHeader
                        }
                    }
                }
            }

            state.breakdownOfGHGEmissionOnScopeTypeList.forEachIndexed { index, breakdownGHGEmissionOnScopeType ->
                DynamicTableWrapper {
                    key = breakdownGHGEmissionOnScopeType.key
                    deleteRowOrSection = { props.deleteRowOrSection(listName, index) }
                    items = state.breakdownOfGHGEmissionOnScopeTypeList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = breakdownGHGEmissionOnScopeType::section3055ScopeType.name
                            value = breakdownGHGEmissionOnScopeType.section3055ScopeType
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGNumberFieldWrapper {
                            name = breakdownGHGEmissionOnScopeType::section3055ReducedEmission.name
                            value = breakdownGHGEmissionOnScopeType.section3055ReducedEmission
                            onChange = { event -> props.onChangeDecimalNumber(event, index, listName) }
                            onBlur = { event -> props.onBlurDecimalNumber(event, index, listName) }
                        }
                    }
                }
            }
        }
    }
}





