package com.ecosave.watch.portal.components.esg.nondiscrimination

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px

val GRI_406_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.nonDiscrimination?.let { state ->
        val nonDiscriminationItemsListName = state::nonDiscriminationItems.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_406_1
        }
        DisclosureSubHeading {
            subHeading = "The reporting organization shall include incidents of discrimination on grounds of race, color, sex, religion, political " +
                    "opinion, national extraction, or social origin as defined by the ILO, or other relevant forms of discrimination involving " +
                    "internal and/or external stakeholders across operations in the reporting period."
        }

        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass
            label {
                +"Total number of incidents of discrimination during the reporting period."
            }
            ESGNumberFieldWrapper {
                name = state::section4061Field1.name
                value = state.section4061Field1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }

        RomanListWrapper(
            title = "Status of the incidents and actions taken with reference to the following:",
            list = listOf(
                "Incident reviewed by the organization;",
                "Remediation plans being implemented;",
                "Remediation plans that have been implemented, with results reviewed through routine internal management review processes;",
                "Incident no longer subject to action."
            ),
            inClassName = ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            Box {
                className = ESGCommonStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRowOrSection = { props.addRowOrSection(nonDiscriminationItemsListName) }
                    items = state.nonDiscriminationItems
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowOrSectionBtnText = "Add a new section for Incident Details"
                    Box {
                        sx {
                            padding = 20.px
                        }
                        DisclosureSubHeading {
                            subHeading = "Incidents"
                        }
                    }
                }
            }
            state.nonDiscriminationItems.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(nonDiscriminationItemsListName, index) }
                    items = state.nonDiscriminationItems
                    action = DynamicTableAction.DELETE
                    Box {
                        className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                        DisclosureSubHeading {
                            subHeading = "Incident ${index + 1}"
                        }
                        Box {
                            label {
                                +"Incident"
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section4061A1.name
                                value = it.section4061A1
                                onChange = { event -> onChangeTextArea(event, index, nonDiscriminationItemsListName) }
                                onBlur = { event -> onBlurTextArea(event, index, nonDiscriminationItemsListName) }
                            }
                        }
                        Box {
                            label {
                                +"Status of the incident and actions taken"
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section4061A2.name
                                value = it.section4061A2
                                onChange = { event -> onChangeTextArea(event, index, nonDiscriminationItemsListName) }
                                onBlur = { event -> onBlurTextArea(event, index, nonDiscriminationItemsListName) }
                            }
                        }
                    }
                }
            }
        }
    }
}