package com.ecosave.watch.portal.components.esg.materialtopic

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import emotion.react.css
import js.intl.ListFormatStyle
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.onChange
import web.cssom.ClassName
import web.cssom.ListStyleType
import web.cssom.px


val Disclosure_3_2 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.disclosuresOnMaterialTopics?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_3_2
        }


        ol {
            Box {
                sx {
                    marginBottom = 10.px
                }
                DisclosureSubHeading {
                    subHeading = "Selected Material Topics"
                }
            }

            className = ClassName("list-style-none")
            props.esgReportState.esgSections.filter {
                it != EsgSection.GENERAL_DISCLOSURES && it != EsgSection.MATERIAL_TOPICS
            }.forEach {
                li {
                    +it.description
                }
            }

        }

        Box {
            label {
                +"Report changes to the list of material topics compared to the previous reporting period."
            }
            ESGTextAreaFieldWrapper {
                name = state::section31A3.name
                value = state.section31A3
                onChange = { event ->
                    onChangeTextArea(event, null, null)
                }
                onBlur = { event ->
                    onBlurTextArea(event, null, null)
                }
            }
        }
    }
}
