package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import react.FC

val GRI_305_3 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.emissions?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_3
        }

        RomanListWrapper(
            title = "When compiling the information specified in Disclosure 305-3, the reporting organization shall:",
            list = listOf(
                "exclude any GHG trades from the calculation of gross other indirect (Scope 3) GHG emissions;",
                "exclude energy indirect (Scope 2) GHG emissions from this disclosure. Energy indirect (Scope 2) GHG " +
                    "emissions are disclosed as specified in Disclosure 305-2;",
                "report biogenic emissions of CO2 from the combustion or biodegradation of biomass that occur in its " +
                    "value chain separately from the gross other indirect (Scope 3) GHG emissions. Exclude biogenic " +
                    "emissions of other types of GHG (such as CH4 and N2O), and biogenic emissions of CO2 that " +
                    "occur in the life cycle of biomass other than from combustion or biodegradation (such as GHG " +
                    "emissions from processing or transporting biomass)."
            ),
            inClassName = ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        ESGBaseFormBuilder(
            listOf(
                DecimalFieldMapper(
                    "Gross other indirect (Scope 3) GHG emissions in metric tons of CO2 equivalent.",
                    state::section3053Current1.name,
                    state.section3053Current1
                ),
                TextAreaFieldMapper(
                    "If available, the gases included in the calculation; whether CO2 , CH4 , N2O, HFCs, PFCs, " +
                        "SF6 , NF3 , or all.",
                    state::section3053Current2.name,
                    state.section3053Current2
                ),
                DecimalFieldMapper(
                    "Biogenic CO2 emissions in metric tons of CO2 equivalent",
                    state::section3053Current3.name,
                    state.section3053Current3
                ),
                TextAreaFieldMapper(
                    "Other indirect (Scope 3) GHG emissions categories and activities included in the " +
                        "calculation.",
                    state::section3053Field1.name,
                    state.section3053Field1
                ),

                TextAreaFieldMapper(
                    "Base year for the calculation, if applicable, including:",
                    state::section3053Field2.name,
                    state.section3053Field2,
                    listHeaders = listOf(
                        "the rationale for choosing it;",
                        "emissions in the base year;",
                        "the context for any significant changes in emissions that triggered recalculations of base " +
                            "year emissions."
                    )
                ),
                TextAreaFieldMapper(
                    "Source of the emission factors and the global warming potential (GWP) rates used, or a " +
                        "reference to the GWP source.",
                    state::section3053Field3.name,
                    state.section3053Field3
                ),
                TextAreaFieldMapper(
                    "Standards, methodologies, assumptions, and/or calculation tools used.",
                    state::section3053Field4.name,
                    state.section3053Field4
                ),
            ),
            props
        )
    }
}