package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_10 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_403_10
        }
        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass

            TableHeader {
                header = "Employees"
            }
            TableHeader {
                header = "Number"
            }

            label {
                +"Fatalities as a result of work-related ill health"
            }
            ESGNumberFieldWrapper {
                name = state::section40310Number1.name
                value = state.section40310Number1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Recordable work-related ill health"
            }
            ESGNumberFieldWrapper {
                name = state::section40310Number2.name
                value = state.section40310Number2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Main types of work-related ill health"
            }
            ESGNumberFieldWrapper {
                name = state::section40310Number3.name
                value = state.section40310Number3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass

            TableHeader {
                header = "Workers who are not employees"
            }
            TableHeader {
                header = "Number"
            }

            label {
                +"Fatalities as a result of work-related ill health"
            }
            ESGNumberFieldWrapper {
                name = state::section40310Number4.name
                value = state.section40310Number4
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Recordable work-related ill health"
            }
            ESGNumberFieldWrapper {
                name = state::section40310Number5.name
                value = state.section40310Number5
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }

            label {
                +"Main types of work-related ill health"
            }
            ESGNumberFieldWrapper {
                name = state::section40310Number6.name
                value = state.section40310Number6
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"What are the Hazard that posed a risk of ill health?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section40310A1.name
                value = state.section40310A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"How was this hazard determined?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section40310A2.name
                value = state.section40310A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Which of these hazards have caused or contributed to cases of ill health during the reporting period?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section40310A3.name
                value = state.section40310A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Actions taken or underway to eliminate these hazards and minimize risks using the hierarchy of controls?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section40310Field1.name
                value = state.section40310Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section40310Field2.name
                value = state.section40310Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Any contextual information necessary to understand how the data have been compiled, such as any standards, methodologies, and assumptions used?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section40310Field3.name
                value = state.section40310Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}