package com.ecosave.watch.portal.components.esg.waste

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_306_4 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.waste?.let { state ->
        val listName = state::breakdownOfTotalWasteDivertedByCompositionList.name
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_306_4
        }

        DisclosureSubHeading {
            subHeading = "Total weight of waste diverted from disposal in metric tons, and a breakdown of this " +
                "total by composition of the waste."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(listName) }
                items = state.breakdownOfTotalWasteDivertedByCompositionList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                    val headers = listOf(
                        "Type of waste",
                        "Waste Streams",
                        "Materials",
                        "Waste Diverted From Disposal",
                    )
                    for (inHeader in headers) {
                        TableHeader {
                            header = inHeader
                        }
                    }
                }
            }
            state.breakdownOfTotalWasteDivertedByCompositionList.forEachIndexed { index, totalWaste ->
                DynamicTableWrapper {
                    key = totalWaste.key
                    deleteRowOrSection = { props.deleteRowOrSection(listName, index) }
                    items = state.breakdownOfTotalWasteDivertedByCompositionList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = totalWaste::section3064TypeOfWaste.name
                            value = totalWaste.section3064TypeOfWaste
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGTextFieldWrapper {
                            name = totalWaste::section3064WasteStream.name
                            value = totalWaste.section3064WasteStream
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGTextFieldWrapper {
                            name = totalWaste::section3064Material.name
                            value = totalWaste.section3064Material
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }

                        ESGNumberFieldWrapper {
                            name = totalWaste::section3064WasteDiverted.name
                            value = totalWaste.section3064WasteDiverted
                            onChange = { event -> props.onChangeDecimalNumber(event, index, listName) }
                            onBlur = { event -> props.onBlurDecimalNumber(event, index, listName) }
                        }
                    }

                }
            }
        }

        RomanListWrapper(
            "Total weight of hazardous waste diverted from disposal in metric tons, and a breakdown " +
                "of this total by the following disposal operations:",
            listOf(
                "Preparation for reuse;",
                "Recycling;",
                "Other recovery operations."
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        RomanListWrapper(
            "For each recovery operation listed in Disclosures 306-4-b, a breakdown of " +
                "the total weight in metric tons of hazardous waste diverted " +
                "from disposal:",
            listOf(
                "Onsite;",
                "Offsite;",
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
            listOf(
                "Recovery Operation", "Onsite", "Offsite", "Total"
            ).forEach {
                TableHeader { header = it }
            }

            listOf(
                Pair(
                    "Preparation for Reuse", listOf(
                        Pair(state::section3064Onsite1.name, state.section3064Onsite1),
                        Pair(state::section3064Offsite1.name, state.section3064Offsite1),
                        Pair(state::section3064Current4.name, state.section3064Current4),
                    )
                ),
                Pair(
                    "Recycling", listOf(
                        Pair(state::section3064Onsite2.name, state.section3064Onsite2),
                        Pair(state::section3064Offsite2.name, state.section3064Offsite2),
                        Pair(state::section3064Current7.name, state.section3064Current7),
                    )
                ),
                Pair(
                    "Other Recovery", listOf(
                        Pair(state::section3064Onsite7.name, state.section3064Onsite7),
                        Pair(state::section3064Offsite7.name, state.section3064Offsite7),
                        Pair(state::section3064Current10.name, state.section3064Current10),
                    )
                ),
            ).forEach {
                label { +it.first }
                it.second.forEach {
                    ESGNumberFieldWrapper {
                        name = it.first
                        value = it.second
                        onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
                    }
                }
            }
            //TOTAL
            label { +"Total weight of hazardous waste diverted from disposal" }
            label { +"" }
            label { +"" }
            ESGNumberFieldWrapper {
                name = state::section3064Current1.name
                value = state.section3064Current1
                onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
            }
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    label = "The organization can specify the type of recycling operations(downcycling, upcycling," +
                        " composting, or anaerobic digestion).",
                    name = state::section3064Field3.name,
                    value = state.section3064Field3
                ),
                TextAreaFieldMapper(
                    label = "The organization can specify other types of recovery operations(repurposing or " +
                        "refurbishment).",
                    name = state::section3064Field4.name,
                    value = state.section3064Field4
                )
            ),
            props
        )

        RomanListWrapper(
            "Total weight of non-hazardous waste directed to disposal in metric tons, and a breakdown " +
                "of this total by the following disposal operations:",
            listOf(
                "Preparation for reuse;",
                "Recycling;",
                "Other recovery operations."
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        RomanListWrapper(
            "For each recovery operation listed in Disclosures 306-4-c, a breakdown of " +
                "the total weight in metric tons of non-hazardous waste diverted " +
                "from disposal:",
            listOf(
                "Onsite;",
                "Offsite;",
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
            listOf(
                "Recovery Operation", "Onsite", "Offsite", "Total"
            ).forEach {
                TableHeader { header = it }
            }

            listOf(
                Pair(
                    "Preparation for Reuse", listOf(
                        Pair(state::section3064Onsite9.name, state.section3064Onsite9),
                        Pair(state::section3064Offsite9.name, state.section3064Offsite9),
                        Pair(state::section3064Current14.name, state.section3064Current14),
                    )
                ),
                Pair(
                    "Recycling", listOf(
                        Pair(state::section3064Onsite10.name, state.section3064Onsite10),
                        Pair(state::section3064Offsite10.name, state.section3064Offsite10),
                        Pair(state::section3064Current17.name, state.section3064Current17),
                    )
                ),
                Pair(
                    "Other Recovery Operations", listOf(
                        Pair(state::section3064Onsite14.name, state.section3064Onsite14),
                        Pair(state::section3064Offsite14.name, state.section3064Offsite14),
                        Pair(state::section3064Current20.name, state.section3064Current20),
                    )
                ),
            ).forEach {
                label { +it.first }
                it.second.forEach {
                    ESGNumberFieldWrapper {
                        name = it.first
                        value = it.second
                        onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
                    }
                }
            }
            //TOTAL
            label { +"Total weight of non-hazardous waste diverted from disposal" }
            label { +"" }
            label { +"" }
            ESGNumberFieldWrapper {
                name = state::section3064Current13.name
                value = state.section3064Current13
                onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
            }
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    label = "The organization can specify the type of recycling operations(downcycling, upcycling," +
                        " composting, or anaerobic digestion).",
                    name = state::section3064Field5.name,
                    value = state.section3064Field5
                ),
                TextAreaFieldMapper(
                    label = "The organization can specify other types of recovery operations(repurposing or " +
                        "refurbishment).",
                    name = state::section3064Field6.name,
                    value = state.section3064Field6
                )
            ),
            props
        )



        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "Provide contextual information necessary to understand the data and how the data has been " +
                        "compiled.",
                    state::section3064Field2.name,
                    state.section3064Field2
                )
            ),
            props
        )

        DisclosureSubHeading {
            subHeading = "The reporting organization should report the total weight of waste prevented, and the " +
                "baseline and methodology for this calculation."
        }

        ESGBaseFormBuilder(
            listOf(
                DecimalFieldMapper(
                    "Total Waste Prevented",
                    state::section3064Total17.name,
                    state.section3064Total17
                ),
                TextAreaFieldMapper(
                    "Provide baseline and methodology for Total Waste Prevented calculation.",
                    state::section3064Field7.name,
                    state.section3064Field7
                )
            ),
            props
        )


    }
}