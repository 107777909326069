package com.ecosave.watch.portal.components.esg.waste

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import react.FC

val GRI_306_2 = FC<EsgSubFormComponentProps>{ props ->

    props.esgReportState.report?.waste?.let { state ->
        DisclosureName{
            disclosure = GriDisclosureTitles.GRI_306_2
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "Actions, including circularity measures, taken to prevent waste generation in the " +
                        "organization’s own activities and upstream and downstream in its value chain, and to manage " +
                        "significant impacts from waste generated.",
                    state::section3062A1.name,
                    state.section3062A1
                ),
                TextAreaFieldMapper(
                    "If the waste generated by the organization in its own activities is managed by a third " +
                        "party, a description of the processes used to determine whether the third party manages the " +
                        "waste in line with contractual or legislative obligations.",
                    state::section3062A2.name,
                    state.section3062A2
                ),
                TextAreaFieldMapper(
                    "The processes used to collect and monitor waste-related data.",
                    state::section3062A3.name,
                    state.section3062A3
                ),
            ),
            props
        )
    }

}