package com.ecosave.watch.portal.components.esg.tax

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.onChange

val Disclosure_207_1 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.tax?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_207_1
        }

        Box {
            RomanListWrapper(
                title = "A description of the approach to tax, including:",
                list = listOf(
                    "whether the organization has a tax strategy and, if so, a link to this strategy if publicly available;",
                    "the governance body or executive-level position within the organization that formally reviews and approves the tax strategy, and the frequency of this review;",
                    "the approach to regulatory compliance;",
                    "how the approach to tax is linked to the business and sustainable development strategies of the organization."
                )
            )

            ESGTextAreaFieldWrapper {
                name = state::section2071A1.name
                value = state.section2071A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }

}