package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DecimalFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import react.FC

val GRI_305_2 = FC<EsgSubFormComponentProps> { props ->
    props.esgReportState.report?.emissions?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_2
        }

        RomanListWrapper(
            title = "When compiling the information specified in Disclosure 305-2, the reporting organization shall:",
            list = listOf(
                "exclude any GHG trades from the calculation of gross energy indirect (Scope 2) GHG emissions;",
                "exclude other indirect (Scope 3) GHG emissions that are disclosed as specified in Disclosure 305-3;",
                "account and report energy indirect (Scope 2) GHG emissions based on the location- based method, if " +
                    "it has operations in markets without product or supplier-specific data;",
                "account and report energy indirect (Scope 2) GHG emissions based on both the location-based and " +
                    "market-based methods, if it has any operations in markets providing product or " +
                    "supplier-specific data in the form of contractual instruments."
            ),
            inClassName = ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        ESGBaseFormBuilder(
            listOf(
                DecimalFieldMapper(
                    "Gross location-based energy indirect (Scope 2) GHG emissions in metric tons of CO2 equivalent.",
                    state::section3052Current1.name,
                    state.section3052Current1
                ),
                DecimalFieldMapper(
                    "If applicable, gross market-based energy indirect (Scope 2) GHG emissions in metric " +
                        "tons of CO2 equivalent.",
                    state::section3052Current2.name,
                    state.section3052Current2
                ),
                TextAreaFieldMapper(
                    "If available, the gases included in the calculation; whether CO2 , CH4 , N2O, HFCs, PFCs, " +
                        " SF6 , NF3 , or all.",
                    state::section3052Current3.name,
                    state.section3052Current3
                ),
                TextAreaFieldMapper(
                    "Base year for the calculation, if applicable, including:",
                    state::section3052Field1.name,
                    state.section3052Field1,
                    listHeaders = listOf(
                        "the rationale for choosing it;",
                        "emissions in the base year;",
                        "the context for any significant changes in emissions that triggered recalculations of base " +
                            "year emissions."
                    )
                ),
                TextAreaFieldMapper(
                    "Source of the emission factors and the global warming potential (GWP) rates used, or a " +
                        "reference to the GWP source.",
                    state::section3052Field2.name,
                    state.section3052Field2
                ),
                TextAreaFieldMapper(
                    "Consolidation approach for emissions; whether equity share, financial control, or " +
                        "operational control.",
                    state::section3052Field3.name,
                    state.section3052Field3
                ),
                TextAreaFieldMapper(
                    "Standards, methodologies, assumptions, and/or calculation tools used.",
                    state::section3052Field4.name,
                    state.section3052Field4
                ),
            ),
            props
        )

    }
}