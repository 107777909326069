package com.ecosave.watch.portal.components.esg.waste

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_306_3 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.waste?.let { state ->
        val listName = state::breakdownOfTotalWasteList.name
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_306_3
        }

        DisclosureSubHeading {
            subHeading = "Total weight of waste generated in metric tons, and a breakdown of this total by composition " +
                "of the waste."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(listName) }
                items = state.breakdownOfTotalWasteList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                    val headers = listOf(
                        "Type of waste",
                        "Waste Streams",
                        "Materials",
                        "Waste Generated"

                    )
                    for (inHeader in headers) {
                        TableHeader {
                            header = inHeader
                        }
                    }
                }
            }
            state.breakdownOfTotalWasteList.forEachIndexed { index, totalWaste ->
                DynamicTableWrapper {
                    key = totalWaste.key
                    deleteRowOrSection = { props.deleteRowOrSection(listName, index) }
                    items = state.breakdownOfTotalWasteList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = totalWaste::section3063TypeOfWaste.name
                            value = totalWaste.section3063TypeOfWaste
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGTextFieldWrapper {
                            name = totalWaste::section3063WasteStream.name
                            value = totalWaste.section3063WasteStream
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGTextFieldWrapper {
                            name = totalWaste::section3063Material.name
                            value = totalWaste.section3063Material
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }

                        ESGNumberFieldWrapper {
                            name = totalWaste::section3063WasteGenerated.name
                            value = totalWaste.section3063WasteGenerated
                            onChange = { event -> props.onChangeDecimalNumber(event, index, listName) }
                            onBlur = { event -> props.onBlurDecimalNumber(event, index, listName) }
                        }
                    }

                }
            }
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "Provide contextual information necessary to understand the data and how the data has been " +
                        "compiled.",
                    state::section3063Field1.name,
                    state.section3063Field1
                ),
            ),
            props
        )
    }

}