package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML
import react.dom.onChange

val GRI_305_7 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.emissions?.let {state->
        val listName = state::breakdownOfOtherEmissionTypeList.name
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_7
        }

        Box{
            className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass

            listOf("Emission Type","Unit", "Value").forEach {
                TableHeader {
                    header = it
                }
            }

            ReactHTML.label {
                +"NOx"
            }
            ESGTextFieldWrapper{
                name = state::section3057Unit1.name
                value = state.section3057Unit1
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3057Current1.name
                value = state.section3057Current1
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

            ReactHTML.label {
                +"SOx"
            }
            ESGTextFieldWrapper{
                name = state::section3057Unit2.name
                value = state.section3057Unit2
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3057Current2.name
                value = state.section3057Current2
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

            ReactHTML.label {
                +"Persistent organic pollutants (POP)"
            }
            ESGTextFieldWrapper{
                name = state::section3057Unit3.name
                value = state.section3057Unit3
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3057Current3.name
                value = state.section3057Current3
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

            ReactHTML.label {
                +"Volatile organic compounds (VOC)"
            }
            ESGTextFieldWrapper{
                name = state::section3057Unit4.name
                value = state.section3057Unit4
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3057Current4.name
                value = state.section3057Current4
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

            ReactHTML.label {
                +"Hazardous air pollutants (HAP)"
            }
            ESGTextFieldWrapper{
                name = state::section3057Unit5.name
                value = state.section3057Unit5
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3057Current5.name
                value = state.section3057Current5
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }

            ReactHTML.label {
                +"Particulate matter (PM)"
            }
            ESGTextFieldWrapper{
                name = state::section3057Unit6.name
                value = state.section3057Unit6
                onChange = {event -> props.onChangeText(event, null, null)}
                onBlur = {event -> props.onBlurText(event, null, null)}
            }
            ESGNumberFieldWrapper{
                name = state::section3057Current6.name
                value = state.section3057Current6
                onChange = {event -> props.onChangeDecimalNumber(event, null, null)}
            }
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(listName) }
                items = state.breakdownOfOtherEmissionTypeList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    val headers = listOf(
                        "Other emission type",
                        "Unit",
                        "Value"
                    )
                    for (inHeader in headers) {
                        TableHeader {
                            header = inHeader
                        }
                    }
                }
            }

            state.breakdownOfOtherEmissionTypeList.forEachIndexed { index, otherEmissionType ->
                DynamicTableWrapper {
                    key = otherEmissionType.key
                    deleteRowOrSection = { props.deleteRowOrSection(listName, index) }
                    items = state.breakdownOfGHGEmissionOnScopeTypeList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = otherEmissionType::section3057A1.name
                            value = otherEmissionType.section3057A1
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGTextFieldWrapper {
                            name = otherEmissionType::section3057B1.name
                            value = otherEmissionType.section3057B1
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGNumberFieldWrapper {
                            name = otherEmissionType::section3057C1.name
                            value = otherEmissionType.section3057C1
                            onChange = { event -> props.onChangeDecimalNumber(event, index, listName) }
                            onBlur = { event -> props.onBlurDecimalNumber(event, index, listName) }
                        }
                    }
                }
            }
        }


        ESGBaseFormBuilder(
            listOf(
                 TextAreaFieldMapper(
                     "Source of the emission factors used.",
                     state::section3057Field1.name,
                     state.section3057Field1,
                 ),
                TextAreaFieldMapper(
                    "Standards, methodologies, assumptions, and/or calculation tools used.",
                    state::section3057Field2.name,
                    state.section3057Field2,
                )
            ),
            props
        )


    }

}