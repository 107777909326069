package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class EmissionsState(
    var section3051GhgDirectEmission: Double? = null,

    var section3051Current1: Double? = null,
    var section3051Current2: String? = null,
    var section3051Current3: Double? = null,

    var section3051Field1: String? = null,
    var section3051Field2: String? = null,
    var section3051Field3: String? = null,
    var section3051Field4: String? = null,

    var section3052Current1: Double? = null,
    var section3052Current2: Double? = null,
    var section3052Current3: String? = null,

    var breakdownOfGHGInDirectList: MutableList<BreakdownGHGInDirect>? = mutableListOf(),

    var totalSection3052GhgLocation: Double? = null,
    var totalSection3052GhgMarket: Double? = null,

    var section3052Field1: String? = null,
    var section3052Field2: String? = null,
    var section3052Field3: String? = null,
    var section3052Field4: String? = null,


    var section3053Current1: Double? = null,
    var section3053Current2: String? = null,
    var section3053Current3: Double? = null,


    var section3053Field1: String? = null,
    var section3053Field2: String? = null,
    var section3053Field3: String? = null,
    var section3053Field4: String? = null,


    var section3054Unit1: String? = null,
    var section3054Unit2: String? = null,
    var section3054Unit3: String? = null,
    var section3054Unit4: String? = null,
    var section3054Unit5: String? = null,
    var section3054Unit6: String? = null,

    var section3054Current1: Double? = null,
    var section3054Current2: Double? = null,
    var section3054Current3: Double? = null,

    var section3054Field1: String? = null,
    var section3054Field2: String? = null,


    var section3055Current1: Double? = null,
    var section3055Current2: String? = null,

    var section3055Field1: String? = null,
    var section3055Field2: String? = null,
    var section3055Field3: String? = null,

    var breakdownOfGHGEmissionOnScopeTypeList: MutableList<BreakdownGHGEmissionOnScopeType> = mutableListOf(
        BreakdownGHGEmissionOnScopeType()
    ),

    var section3056Current1: Double? = null,
    var section3056Current2: Double? = null,
    var section3056Current3: Double? = null,
    var section3056Current4: Double? = null,

    var section3056Field1: Double? = null,
    var section3056Field2: Double? = null,
    var section3056Field3: String? = null,
    var section3056Field4: String? = null,
    var section3056Field5: String? = null,

    var section3057Unit1: String? = null,
    var section3057Unit2: String? = null,
    var section3057Unit3: String? = null,
    var section3057Unit4: String? = null,
    var section3057Unit5: String? = null,
    var section3057Unit6: String? = null,

    var section3057Current1: Double? = null,
    var section3057Current2: Double? = null,
    var section3057Current3: Double? = null,
    var section3057Current4: Double? = null,
    var section3057Current5: Double? = null,
    var section3057Current6: Double? = null,
    var section3057Current7: Double? = null,

    var breakdownOfOtherEmissionTypeList: MutableList<OtherEmissionType> = mutableListOf(OtherEmissionType()),

    var section3057Field1: String? = null,
    var section3057Field2: String? = null,
    var section3057Field3: String? = null,

    var section3_3_305A1: String? = null,
    var section3_3_305A2: String? = null,
    var section3_3_305A3: String? = null,
    var section3_3_305A4: String? = null,
    var section3_3_305A5: String? = null,
    var section3_3_305A6: String? = null
)

@Serializable
data class BreakdownGHGDirect(
    var section3051Group: String? = null,
    var section3051Activity: String? = null,
    var section3051Source: String? = null,
    var section3051Country: String? = null,
    var section3051GhgDirectEmission: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class BreakdownGHGInDirect(
    var section3052Group: String? = null,
    var section3052Activity: String? = null,
    var section3052Source: String? = null,
    var section3052Country: String? = null,
    var section3052GhgLocation: Double? = null,
    var section3052GhgMarket: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class BreakdownGHGEmissionOnScopeType(
    var section3055ScopeType: String? = null,
    var section3055ReducedEmission: Double? = null
) : DynamicTableItemKey()

@Serializable
data class OtherEmissionType(
    var section3057A1: String? =  null,
    var section3057B1:String? =  null,
    var section3057C1: Double? =  null
) : DynamicTableItemKey()
