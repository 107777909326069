package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DecimalFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import react.FC

val GRI_305_1 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.emissions?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_1
        }

        RomanListWrapper(
            title = "When compiling the information specified in Disclosure 305-1, the reporting organization shall:",
            list = listOf(
                "exclude any GHG trades from the calculation of gross direct (Scope 1) GHG 2.1.2 emissions;",

                "report biogenic emissions of CO2 from the combustion or biodegradation of biomass separately from the" +
                " gross direct (Scope 1) GHG emissions. Exclude biogenic emissions of other types of GHG (such as" +
                    " CH4 and N2O), and biogenic emissions of CO2 that occur in the life cycle of biomass other " +
                    "than from combustion or biodegradation (such as GHG emissions from processing or " +
                    "transporting biomass).",
            ),
            inClassName = ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        ESGBaseFormBuilder(
            listOf(
                DecimalFieldMapper(
                    "Gross direct (Scope 1) GHG emissions in metric tons of CO2 equivalent",
                    state::section3051Current1.name,
                    state.section3051Current1
                ),
                TextAreaFieldMapper(
                    "Gases included in the calculation; whether CO2 , CH4 , N2O, HFCs, PFCs, SF6 , NF3 , or all",
                    state::section3051Current2.name,
                    state.section3051Current2
                ),
                DecimalFieldMapper(
                    "Biogenic CO2 emissions in metric tons of CO2 equivalent",
                    state::section3051Current3.name,
                    state.section3051Current3
                ),
                TextAreaFieldMapper(
                    "Base year for the calculation, if applicable, including:",
                    state::section3051Field1.name,
                    state.section3051Field1,
                    listHeaders = listOf(
                        "the rationale for choosing it;",
                        "emissions in the base year;",
                        "the context for any significant changes in emissions that triggered recalculations of base " +
                            "year emissions."
                    )
                ),
                TextAreaFieldMapper(
                    "Source of the emission factors and the global warming potential (GWP) rates used, or a " +
                        "reference to the GWP source.",
                    state::section3051Field2.name,
                    state.section3051Field2
                ),
                TextAreaFieldMapper(
                    "Consolidation approach for emissions; whether equity share, financial control, or " +
                        "operational control.",
                    state::section3051Field3.name,
                    state.section3051Field3
                ),
                TextAreaFieldMapper(
                    "Standards, methodologies, assumptions, and/or calculation tools used.",
                    state::section3051Field4.name,
                    state.section3051Field4
                ),
            ),
            props = props
        )
    }
}