package com.ecosave.watch.portal.components.esg.formcontrols

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.px

external interface LabelProps : Props {
    var label: String
    var subItemLeftMargin: Boolean
}

val Label = FC<LabelProps> {
    ReactHTML.label {
        +it.label
        css {
            if (it.subItemLeftMargin) {
                marginLeft = 50.px
            }
        }
    }
}