package com.ecosave.watch.portal.components.esg.tax

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.onChange

val Disclosure_207_3 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.tax?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_207_3
        }

        Box {
            RomanListWrapper(
                title = "A description of the approach to stakeholder engagement and management of stakeholder concerns related to tax, including:",
                list = listOf(
                    "the approach to engagement with tax authorities;",
                    "the approach to public policy advocacy on tax;",
                    "the processes for collecting and considering the views and concerns of stakeholders, including external stakeholders.",
                )
            )

            ESGTextAreaFieldWrapper {
                name = state::section2073A1.name
                value = state.section2073A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }

}