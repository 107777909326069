package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.esg.getReportCoverImageUrl
import com.ecosave.watch.portal.services.esg.uploadReportCoverImage
import emotion.react.css
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.Grid
import mui.material.Input
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML
import react.useEffectOnce
import react.useState
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.file.File
import web.html.HTMLInputElement

external interface UploadReportCoverImageComponentProps : Props {
    var showUploadReportCoverImageDialog: Boolean
    var setShowUploadReportCoverImageDialog: StateSetter<Boolean>
}

val UploadReportCoverImageComponent = FC<UploadReportCoverImageComponentProps> { props ->

    var selectedCoverImage by useState<File>()
    var coverImageUrl by useState("")
    var isLoading by useState(false)
    var isSaving by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    fun fetchReportCoverImageUrl() {
        mainScope.launch {
            isLoading = true
            val url = getReportCoverImageUrl()
            coverImageUrl = url ?: ""
            isLoading = false
        }
    }

    useEffectOnce {
        fetchReportCoverImageUrl()
    }

    DialogWrapper {
        open = props.showUploadReportCoverImageDialog
        dialogMaxWidth = 900
        DialogContent {
            Grid {
                container = true
                spacing = responsive(3)
                Grid {
                    item = true
                    xs = 12
                    Typography {
                        sx {
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.h6
                        align = TypographyAlign.left
                        +"Report Cover Page"
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    if (isLoading) {
                        +"Fetching report cover page..."
                    } else {
                        if (coverImageUrl.isBlank()) {
                            Typography {
                                variant = TypographyVariant.h6
                                align = TypographyAlign.left
                                +"There has been an issue fetching cover page."
                            }
                        } else {
                            ReactHTML.img {
                                css {
                                    width = 100.pct
                                    height = 100.pct
                                }
                                alt = "Loading image..."
                                key = coverImageUrl
                                src = coverImageUrl
                            }
                        }
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    Typography {
                        sx {
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.h6
                        +"Update Report Cover Page"
                        align = TypographyAlign.left
                    }
                }
                Grid {
                    item = true
                    xs = 12
                    Input {
                        type = "file"
                        onChange = {
                            val target = it.target as HTMLInputElement
                            selectedCoverImage = target.files?.get(0)
                        }
                    }
                }
            }
        }
        DialogActions {
            sx {
                justifyContent = JustifyContent.center
            }
            Button {
                +"Cancel"
                onClick = {
                    selectedCoverImage = null
                    props.setShowUploadReportCoverImageDialog(false)
                }
            }
            Button {
                variant = ButtonVariant.contained
                disabled = isSaving || selectedCoverImage == null
                +"Update"
                onClick = {
                    mainScope.launch {
                        isSaving = true
                        val status = uploadReportCoverImage(selectedCoverImage!!)
                        if (status == ApiCallStatus.SUCCESS) {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.SUCCESS,
                                    message = "Cover page updated successfully.",
                                    visible = true
                                )
                            )
                            fetchReportCoverImageUrl()
                        } else {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.ERROR,
                                    message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                    visible = true
                                )
                            )
                        }
                        isSaving = false
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    DialogSpinner {
        open = isSaving
        message = "Updating cover page..."
    }
}