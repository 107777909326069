package com.ecosave.watch.portal.helpers.esg

enum class DynamicTableAction(val actionName: String) {
    ADD("add"),
    DELETE("delete")
}

enum class DynamicTableVariant(val variantName: String) {
    LIST("list"),
    TABLE("table")
}

enum class TOCSectionsEnum {
    // General Disclosures
    THE_ORG_AND_ITS_REP_PRACTICES,
    ACTIVITIES_AND_WORKERS,
    GOVERNANCE,
    STRATEGY_POLICIES_PRACTICES,
    STAKEHOLDER_ENGAGEMENT,

    // Other Sections
    INDIRECT_ECONOMIC_IMPACTS,
    PROCUREMENT_PRACTICES,
    ANTI_COMPETITIVE_BEHAVIOR,
    OCCUPATION_HEALTH_AND_SAFETY,
    EMPLOYMENT,
    ECONOMIC_PERFORMANCE,
    TAX,
    MARKET_PRESENCE,
    CUSTOMER_HEALTH_AND_SAFETY,
    MARKETING_AND_LABELING,
    CUSTOMER_PRIVACY,
    PUBLIC_POLICY,
    SUPPLIER_SOCIAL_ASSESSMENT,
    LOCAL_COMMUNITY,
    SECURITY_PRACTICES,
    FORCED_OR_COMPULSORY_LABOR,
    FREEDOM_OF_ASSOCIATION_AND_COLLECTIVE_BARGAINING,
    CHILD_LABOR,
    MANAGEMENT_RELATIONS,
    RIGHTS_OF_INDIGENOUS_PEOPLES,
    DIVERSITY_AND_EQUAL_OPPORTUNITY,
    NON_DISCRIMINATION,
    TRAINING_AND_EDUCATION,
    MATERIALS,
    SUPPLIER_ENVIRONMENTAL_ASSESSMENT,
    ENERGY,
    ANTI_CORRUPTION,
    WATER_AND_EFFLUENTS,
    BIODIVERSITY,
    EMISSIONS,
    MATERIAL_TOPICS,
    WASTE
}

enum class ReportSummaryEnums(val description: String) {
    REPORT_FILE_NAME("Report File Name"),
    REPORT_TITLE("Report Title"),
    REPORT_FINANCIAL_YEAR("Report Financial Year"),
    LAST_MODIFIED_BY("Last Modified By"),
    LAST_MODIFIED_TIME("Last Modified Time"),
    REPORT_ACTIONS("Actions")
}

enum class EsgSection(val description: String, val info: String) {
    GENERAL_DISCLOSURES(
        "GRI 2: General Disclosures 2021",
        "General Disclosures contains disclosures for organizations to provide information about their reporting practices; activities and workers; governance; strategy, policies, and practices; and stakeholder engagement. This information gives insight into the profile and scale of organizations and provides a context for understanding their impacts. "
    ),
    MATERIAL_TOPICS(
        "GRI 3: Material topics 2021",
        "Material Topics provides step-by-step guidance for organizations on how to determine material topics. It also explains how the Sector Standards are used in this process. Material topics are topics that represent an organization’s most significant impacts on the economy, environment, and people, including impacts on their human rights."
    ),
    ECONOMIC_PERFORMANCE(
        "GRI 201: Economic Performance 2016",
        "This Standard addresses the topic of economic performance. This includes the economic value generated and distributed (EVG&D) by an organization, its defined benefit plan obligations, the financial assistance it receives from any government, and the financial implications of climate change. "
    ),
    MARKET_PRESENCE(
        "GRI 202 Market Presence 2016",
        "This Standard addresses the topic of an organization’s market presence, covering its contribution to economic development in the local areas or communities where it operates. For example, this can include the organization’s approaches to remuneration or local hiring. "
    ),
    INDIRECT_ECONOMIC_IMPACTS(
        "GRI 203: Indirect Economic Impacts 2016",
        "This Standard addresses an organization’s indirect economic impacts, including impacts of an organization’s infrastructure investments and services supported. "
    ),
    PROCUREMENT_PRACTICES(
        "GRI 204: Procurement Practices 2016",
        "This Standard addresses the topic of procurement practices. This covers an organization’s support for local suppliers or those owned by women or members of vulnerable groups. It also covers how the organization’s procurement practices (such as the lead times it gives to suppliers, or the purchasing prices it negotiates) cause or contribute to negative impacts in the supply chain. "
    ),
    ANTI_CORRUPTION(
        "GRI 205: Anti-corruption",
        "This Standard addresses the topic of anti-corruption. In this Standard, corruption is understood to include practices such as bribery, facilitation payments, fraud, extortion, collusion, and money laundering; the offer or receipt of gifts, loans, fees, rewards, or other advantages as an inducement to do something that is dishonest, illegal, or represents a breach of trust. It can also include practices such as embezzlement, trading in influence, abuse of function, illicit enrichment, concealment, and obstructing justice. "
    ),
    ANTI_COMPETITIVE_BEHAVIOR(
        "GRI 206: Anti-Competitive behavior 2016",
        "This Standard addresses the topic of anti-competitive behavior, including anti-trust and monopoly practices. "
    ),
    TAX("GRI 207: Tax 2019", "This Standard addresses the topic of tax. "),
    MATERIALS(
        "GRI 301: Materials 2016",
        "Materials contains disclosures for organizations to report information about their materials- related impacts, and how they manage these impacts."
    ),
    ENERGY(
        "GRI 302: Energy 2016",
        "Energy contains disclosures for organizations to report information about their energy-related impacts, and how they manage these impacts."
    ),
    WATER_AND_EFFLUENTS(
        "GRI 303: Water and Effluents 2018",
        "Water and Effluents contains disclosures for organizations to report information about their water- related impacts, and how they manage these impacts."
    ),
    BIODIVERSITY(
        "GRI 304: Biodiversity 2016",
        "Biodiversity contains disclosures for organizations to report information about their biodiversity-related impacts, and how they manage these impacts."
    ),
    EMISSIONS(
        "GRI 305: Emissions",
        "Emissions contains disclosures for organizations to report information about their emissions-related impacts, and how they manage these impacts."
    ),
    WASTE(
        "GRI 306: Waste 2020",
        "Waste contains disclosures for organizations to report information about their waste-related impacts, and how they manage these impacts. The disclosures enable an organization to provide information on how it prevents waste generation and how it manages waste that cannot be prevented, in its own activities and upstream and downstream in its value chain."
    ),
    SUPPLIER_ENVIRONMENTAL_ASSESSMENT(
        "GRI 308: Supplier Environmental Assessment 2016",
        "Supplier Environmental Assessment contains disclosures for organizations to report information about their environmental impacts in their supply chain, and how they manage these impacts. The disclosures enable an organization to provide information on its approach to preventing and mitigating negative environmental impacts in its supply chain."
    ),
    EMPLOYMENT(
        "GRI 401: Employment 2016",
        "Employment contains disclosures for organizations to report information about their employment- related impacts, and how they manage these impacts."
    ),
    MANAGEMENT_RELATIONS(
        "GRI 402: Labor/Management Relations 2016",
        "Labor/Management Relations contains disclosures for organizations to report information about their impacts related to labor/management relations, and how they manage these impacts."
    ),
    OCCUPATION_HEALTH_AND_SAFETY(
        "GRI 403: Occupational Health and Safety 2018",
        "Occupational Health and Safety contains disclosures for organizations to report information about their occupational health and safety-related impacts, and how they manage these impacts."
    ),
    TRAINING_AND_EDUCATION(
        "GRI 404: Training and Education 2016",
        "Training and Education contains disclosures for organizations to report information about their training and education-related impacts, and how they manage these impacts."
    ),
    DIVERSITY_AND_EQUAL_OPPORTUNITY(
        "GRI 405: Diversity and Equal Opportunity",
        "Diversity and Equal Opportunity contains disclosures for organizations to report information about their impacts related to diversity and equal opportunity at work, and how they manage these impacts."
    ),
    NON_DISCRIMINATION(
        "GRI 406: Non-Discrimination",
        "Non-discrimination contains disclosures for organizations to report information about their impacts related to discrimination, and how they manage these impacts."
    ),
    FREEDOM_OF_ASSOCIATION_AND_COLLECTIVE_BARGAINING(
        "GRI 407: Freedom of Association and Collective Bargaining",
        "Freedom of Association and Collective Bargaining contains disclosures for organizations to report information about their impacts related to freedom of association and collective bargaining, and how they manage these impacts."
    ),
    CHILD_LABOR(
        "GRI 408: Child Labor",
        "Child Labor contains disclosures for organizations to report information about their impacts related to child labor, and how they manage these impacts."
    ),
    FORCED_OR_COMPULSORY_LABOR(
        "GRI 409: Forced or Compulsory Labor",
        "Forced or Compulsory Labor contains disclosures for organizations to report information about their impacts related to forced or compulsory labor, and how they manage these impacts."
    ),
    SECURITY_PRACTICES(
        "GRI 410: Security Practices 2016",
        "Security Practices contains disclosures for organizations to report information about their security practice-related impacts, and how they manage these impacts."
    ),
    RIGHTS_OF_INDIGENOUS_PEOPLES(
        "GRI 411: Rights of Indigenous Peoples 2016",
        "Rights of Indigenous Peoples contains disclosures for organizations to report information about their impacts related to the rights of indigenous peoples, and how they manage these impacts."
    ),
    LOCAL_COMMUNITY(
        "GRI 413: Local Communities 2016",
        "Local Communities contains disclosures for organizations to report information about their impacts related to local communities, and how they manage these impacts."
    ),
    SUPPLIER_SOCIAL_ASSESSMENT(
        "GRI 414: Supplier Social Assessment 2016",
        "Supplier Social Assessment contains disclosures for organizations to report information about their social impacts in their supply chain, and how they manage these impacts. The disclosures enable an organization to provide information on its approach to preventing and mitigating negative social impacts in its supply chain."
    ),
    PUBLIC_POLICY(
        "GRI 415: Public Policy 2016",
        "Public Policy contains disclosures for organizations to report information about their public policy- related impacts, and how they manage these impacts."
    ),
    CUSTOMER_HEALTH_AND_SAFETY(
        "GRI 416: Customer Health and Safety 2016",
        "Customer Health and Safety contains disclosures for organizations to report information about their impacts related to customer health and safety, and how they manage these impacts."
    ),
    MARKETING_AND_LABELING(
        "GRI 417: Marketing and Labeling 2016",
        "Section 417 addresses the topic of product and service information and labeling and marketing communications."
    ),
    CUSTOMER_PRIVACY(
        "GRI 418: Customer Privacy 2016",
        "Marketing and Labeling contains disclosures for organizations to report information about their impacts related to marketing and labeling, and how they manage these impacts."
    )
}

enum class EsgSubSection(val mainSection: EsgSection, val subSection: String) {
    // General Disclosures Subsections
    THE_ORG_AND_ITS_REP_PRACTICES(EsgSection.GENERAL_DISCLOSURES, "The Organization and it's reporting practices"),
    ACTIVITIES_AND_WORKERS(EsgSection.GENERAL_DISCLOSURES, "Activities and Workers"),
    GOVERNANCE(EsgSection.GENERAL_DISCLOSURES, "Governance"),
    STRATEGY_POLICIES_PRACTICES(EsgSection.GENERAL_DISCLOSURES, "Strategy, policies, and practices"),
    STAKEHOLDER_ENGAGEMENT(EsgSection.GENERAL_DISCLOSURES, "Stakeholder engagement")
}

enum class CreateReportFields(val label: String) {
    FILE_NAME("File Name *"),
    REPORT_TITLE("Report Title *"),
    COMPANY_NAME("Company Name  *"),
    REPORT_FINANCIAL_INFO("Reporting Financial Information Details"),
    REPORT_FINANCIAL_YEAR("Financial Year (yyyy)  *"),
    REPORT_CAL_DATE_START("Calendar Date Start *"),
    REPORT_CAL_DATE_END("Calendar Date End *")
}

enum class EsgPages {
    CREATE_REPORT,
    SELECT_GRI_STANDARDS,
    TABLE_OF_CONTENTS,
    SELECTED_GRI_STANDARD_FORM,
    ESG_REPORTS
}