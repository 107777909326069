package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.components.common.DialogWrapper
import com.ecosave.watch.portal.components.esg.anticompetitivebehavior.AntiCompetitiveBehaviorComponent
import com.ecosave.watch.portal.components.esg.anticorruption.AntiCorruptionComponent
import com.ecosave.watch.portal.components.esg.biodiversity.BiodiversityComponent
import com.ecosave.watch.portal.components.esg.childlabor.ChildLaborComponent
import com.ecosave.watch.portal.components.esg.customerhealthnsafety.CustomerHealthAndSafetyComponent
import com.ecosave.watch.portal.components.esg.customerprivacy.CustomerPrivacyComponent
import com.ecosave.watch.portal.components.esg.diversityandequalopportunity.DiversityAndEqualOpportunityComponent
import com.ecosave.watch.portal.components.esg.economicperformance.EconomicPerformanceComponent
import com.ecosave.watch.portal.components.esg.emissions.EmissionsComponent
import com.ecosave.watch.portal.components.esg.employment.EmploymentComponent
import com.ecosave.watch.portal.components.esg.energy.EnergyComponent
import com.ecosave.watch.portal.components.esg.forcedorcompulsorylabor.ForcedOrCompulsoryLaborComponent
import com.ecosave.watch.portal.components.esg.freedomofassociationandcollectivebargaining.FreedomOfAssociationAndCollectiveBargainingComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.activitiesandworkers.ActivitiesAndWorkersComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.governance.GovernanceComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.organizationanditsreportingpractices.OrganizationAndItsReportingPracticesComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.stakeholderengagement.StakeholderEngagementComponent
import com.ecosave.watch.portal.components.esg.generaldisclosures.strategypoliciesandpractices.StrategyPoliciesAndPracticesComponent
import com.ecosave.watch.portal.components.esg.indirecteconomicimpacts.IndirectEconomicImpactsComponent
import com.ecosave.watch.portal.components.esg.labormanagementrelations.LaborAndManagementRelationsComponent
import com.ecosave.watch.portal.components.esg.localcommunity.LocalCommunityComponent
import com.ecosave.watch.portal.components.esg.marketingandlabeling.MarketingAndLabelingComponent
import com.ecosave.watch.portal.components.esg.marketpresence.MarketPresenceComponent
import com.ecosave.watch.portal.components.esg.materials.MaterialsComponent
import com.ecosave.watch.portal.components.esg.materialtopic.MaterialTopicComponent
import com.ecosave.watch.portal.components.esg.nondiscrimination.NonDiscriminationComponent
import com.ecosave.watch.portal.components.esg.occupationhealthandsafety.OccupationHealthAndSafetyComponent
import com.ecosave.watch.portal.components.esg.procurementpractices.ProcurementPracticesComponent
import com.ecosave.watch.portal.components.esg.publicpolicy.PublicPolicyComponent
import com.ecosave.watch.portal.components.esg.rightsofindigenouspeoples.RightsOfIndigenousPeoplesComponent
import com.ecosave.watch.portal.components.esg.securitypractices.SecurityPracticesComponent
import com.ecosave.watch.portal.components.esg.supplierenvironmentalassessment.SupplierEnvironmentalAssessmentComponent
import com.ecosave.watch.portal.components.esg.suppliersocialassessment.SupplierSocialAssessmentComponent
import com.ecosave.watch.portal.components.esg.tax.TaxComponent
import com.ecosave.watch.portal.components.esg.trainingandeducation.TrainingAndEducationComponent
import com.ecosave.watch.portal.components.esg.waste.WasteComponent
import com.ecosave.watch.portal.components.esg.waterandeffluents.WaterAndEffluentsComponent
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.useDebounce
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgPages
import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.helpers.esg.autoSaveEsgCollectionField
import com.ecosave.watch.portal.helpers.esg.autoSaveEsgField
import com.ecosave.watch.portal.helpers.esg.debounceDelay
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.useGlobalState
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.icons.material.KeyboardArrowUp
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DialogContent
import mui.material.IconButton
import mui.system.Box
import mui.system.sx
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions
import react.FC
import react.Props
import react.StateSetter
import react.useEffect
import react.useState
import web.cssom.ClassName
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.px
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface EsgMainComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var visiblePage: EsgPages
    var setVisiblePage: StateSetter<EsgPages>
}

val EsgMainComponent = FC<EsgMainComponentProps> { props ->

    val autoSaveDebounce = useDebounce(debounceDelay)
    val visiblePage = props.visiblePage
    val setVisiblePage = props.setVisiblePage
    val reportState = props.reportState
    val setReportState = props.setReportState
    val (openAlertNotifications, setOpenAlertNotifications) = useState(false)
    val (notificationStatus, setNotificationStatus) = useState(NotificationStatus.SUCCESS)
    val (notificationMessage, setNotificationMessage) = useState("")
    val (inputState, setInputState) = useState(CurrentInputState(null, "", 0, JsonPrimitive(""), "", ""))
    var tableOfContentsClickedSection by useState<TOCSectionsEnum>()
    val (addDeleteRowOrSectionApiCallInProgress, setAddDeleteRowOrSectionApiCallInProgress) = useState(false)
    val (addDeleteRowOrSection, setAddDeleteRowOrSection) = useState(DynamicTableAction.ADD)
    var showAutoSaveErrorDialog by useState(false)
    var isLoading by useState(false)
    val autoSaveFailureData by useState(mutableMapOf<String, CurrentInputState>())
    val globalState = useGlobalState()
    var showScrollToTopButton by useState(false)

    useEffect {
        window.addEventListener("scroll", { _ ->
            showScrollToTopButton = window.pageYOffset > 300
        })
    }

    suspend fun autoSave(fieldName: String, parentPath: String?, index: Int?, value: JsonPrimitive?, objectName: String): ApiCallStatus {
        val status: ApiCallStatus?
        if (parentPath == null) {
            status = autoSaveEsgField(
                path = fieldName,
                value = value,
                objectName = objectName,
                reportName = reportState.reportFileName
            )
        } else {
            status = autoSaveEsgCollectionField(
                parentPath = parentPath,
                childPath = fieldName,
                index = index!!,
                value = value,
                objectName = objectName,
                reportName = reportState.reportFileName
            )
        }

        if (status == ApiCallStatus.FAILURE) {
            val currentState = CurrentInputState(
                parentPath = parentPath,
                childPath = fieldName,
                index = index,
                value = value,
                objectName = objectName,
                reportName = reportState.reportFileName
            )
            autoSaveFailureData[currentState.childPath] = currentState
            showAutoSaveErrorDialog = true
        }
        return status
    }

    val autoSaveHandler: () -> Unit = {
        mainScope.launch {
            autoSave(inputState.childPath, inputState.parentPath, inputState.index ?: 0, inputState.value, inputState.objectName)
        }
    }


    useEffect(reportState) {
        if (inputState.childPath !== "") {
            autoSaveDebounce(autoSaveHandler)
        }
    }

    val onBlurApiCall: (String, JsonPrimitive, Int?, String?, String) -> Unit = { fieldName, value, index, parentPath, objectName ->
        mainScope.launch {
            autoSave(fieldName, parentPath, index, value, objectName)
        }
    }

    fun showNotification(message: String, status: NotificationStatus) {
        setNotificationStatus(status)
        setNotificationMessage(message)
        setOpenAlertNotifications(true)
    }

    Box {
        className = styles.MAIN_ESG_FORM.cssClass
        NavigationComponent {
            this.visiblePage = visiblePage
            this.setVisiblePage = setVisiblePage
            griStandard = tableOfContentsClickedSection
            reportFileName = reportState.reportFileName
        }
        when (visiblePage) {
            EsgPages.CREATE_REPORT -> {
                CreateReportComponent {
                    this.reportState = reportState
                    this.setReportState = setReportState
                    this.setVisiblePage = setVisiblePage
                }
            }

            EsgPages.SELECT_GRI_STANDARDS -> {
                SelectGriStandardsComponent {
                    this.reportState = reportState
                    this.setReportState = setReportState
                    this.setVisiblePage = setVisiblePage
                }
            }

            EsgPages.TABLE_OF_CONTENTS -> {
                TableOfContentsComponent {
                    this.reportState = reportState
                    this.setReportState = setReportState
                    tocClickedSection = {
                        tableOfContentsClickedSection = it
                        setVisiblePage(EsgPages.SELECTED_GRI_STANDARD_FORM)
                    }
                }
            }

            EsgPages.SELECTED_GRI_STANDARD_FORM -> {
                val selectedComponent = when (tableOfContentsClickedSection) {
                    TOCSectionsEnum.THE_ORG_AND_ITS_REP_PRACTICES -> OrganizationAndItsReportingPracticesComponent
                    TOCSectionsEnum.ACTIVITIES_AND_WORKERS -> ActivitiesAndWorkersComponent
                    TOCSectionsEnum.GOVERNANCE -> GovernanceComponent
                    TOCSectionsEnum.STAKEHOLDER_ENGAGEMENT -> StakeholderEngagementComponent
                    TOCSectionsEnum.STRATEGY_POLICIES_PRACTICES -> StrategyPoliciesAndPracticesComponent
                    TOCSectionsEnum.PROCUREMENT_PRACTICES -> ProcurementPracticesComponent
                    TOCSectionsEnum.ANTI_COMPETITIVE_BEHAVIOR -> AntiCompetitiveBehaviorComponent
                    TOCSectionsEnum.OCCUPATION_HEALTH_AND_SAFETY -> OccupationHealthAndSafetyComponent
                    TOCSectionsEnum.ECONOMIC_PERFORMANCE -> EconomicPerformanceComponent
                    TOCSectionsEnum.EMPLOYMENT -> EmploymentComponent
                    TOCSectionsEnum.INDIRECT_ECONOMIC_IMPACTS -> IndirectEconomicImpactsComponent
                    TOCSectionsEnum.MARKET_PRESENCE -> MarketPresenceComponent
                    TOCSectionsEnum.TAX -> TaxComponent
                    TOCSectionsEnum.CUSTOMER_HEALTH_AND_SAFETY -> CustomerHealthAndSafetyComponent
                    TOCSectionsEnum.PUBLIC_POLICY -> PublicPolicyComponent
                    TOCSectionsEnum.MARKETING_AND_LABELING -> MarketingAndLabelingComponent
                    TOCSectionsEnum.CUSTOMER_PRIVACY -> CustomerPrivacyComponent
                    TOCSectionsEnum.SUPPLIER_SOCIAL_ASSESSMENT -> SupplierSocialAssessmentComponent
                    TOCSectionsEnum.FORCED_OR_COMPULSORY_LABOR -> ForcedOrCompulsoryLaborComponent
                    TOCSectionsEnum.LOCAL_COMMUNITY -> LocalCommunityComponent
                    TOCSectionsEnum.SECURITY_PRACTICES -> SecurityPracticesComponent
                    TOCSectionsEnum.FREEDOM_OF_ASSOCIATION_AND_COLLECTIVE_BARGAINING -> FreedomOfAssociationAndCollectiveBargainingComponent
                    TOCSectionsEnum.CHILD_LABOR -> ChildLaborComponent
                    TOCSectionsEnum.MANAGEMENT_RELATIONS -> LaborAndManagementRelationsComponent
                    TOCSectionsEnum.RIGHTS_OF_INDIGENOUS_PEOPLES -> RightsOfIndigenousPeoplesComponent
                    TOCSectionsEnum.DIVERSITY_AND_EQUAL_OPPORTUNITY -> DiversityAndEqualOpportunityComponent
                    TOCSectionsEnum.NON_DISCRIMINATION -> NonDiscriminationComponent
                    TOCSectionsEnum.TRAINING_AND_EDUCATION -> TrainingAndEducationComponent
                    TOCSectionsEnum.MATERIALS -> MaterialsComponent
                    TOCSectionsEnum.SUPPLIER_ENVIRONMENTAL_ASSESSMENT -> SupplierEnvironmentalAssessmentComponent
                    TOCSectionsEnum.ENERGY -> EnergyComponent
                    TOCSectionsEnum.ANTI_CORRUPTION -> AntiCorruptionComponent
                    TOCSectionsEnum.WATER_AND_EFFLUENTS -> WaterAndEffluentsComponent
                    TOCSectionsEnum.BIODIVERSITY -> BiodiversityComponent
                    TOCSectionsEnum.EMISSIONS -> EmissionsComponent
                    TOCSectionsEnum.WASTE -> WasteComponent
                    TOCSectionsEnum.MATERIAL_TOPICS -> MaterialTopicComponent
                    else -> null
                }
                Box {
                    if (showScrollToTopButton) {
                        IconButton {
                            className = ClassName("scroll-to-top")
                            onClick = {
                                window.scrollTo(ScrollToOptions(top = 0.0, behavior = ScrollBehavior.SMOOTH))
                            }
                            KeyboardArrowUp {}
                        }
                    }
                }
                selectedComponent?.let {
                    globalState.updatePageTitle("")
                    it {
                        this.reportState = reportState
                        this.setReportState = setReportState
                        this.setInputState = setInputState
                        this.setNotificationMessage = setNotificationMessage
                        this.setNotificationStatus = setNotificationStatus
                        this.setOpenAlertNotifications = setOpenAlertNotifications
                        this.setAddDeleteRowOrSectionApiCallInProgress = setAddDeleteRowOrSectionApiCallInProgress
                        this.setAddDeleteRowOrSection = setAddDeleteRowOrSection
                        this.onBlurApiCall = onBlurApiCall
                    }
                }
            }

            else -> {}
        }
    }
    AlertNotifications {
        open = openAlertNotifications
        message = notificationMessage
        status = notificationStatus
        closeNotification = {
            setOpenAlertNotifications(false)
        }
    }
    DialogSpinner {
        open = addDeleteRowOrSectionApiCallInProgress
        message = when (addDeleteRowOrSection) {
            DynamicTableAction.ADD -> "Adding..."
            DynamicTableAction.DELETE -> "Deleting..."
        }
    }
    DialogWrapper {
        open = showAutoSaveErrorDialog
        DialogContent {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                gap = 20.px
            }
            Box {
                +"We encountered an issue. Either your system is not connected to network or our server is down. As a result, your recent data might not have been saved successfully."
            }
            Button {
                variant = ButtonVariant.contained
                disabled = isLoading
                onClick = {
                    mainScope.launch {
                        isLoading = true

                        val keysToRemove = mutableListOf<String>()

                        autoSaveFailureData.forEach { (key, value) ->
                            if (autoSave(value.childPath, value.parentPath, value.index, value.value, value.objectName) == ApiCallStatus.SUCCESS) {
                                keysToRemove.add(key)
                            }
                        }

                        keysToRemove.forEach { key ->
                            autoSaveFailureData.remove(key)
                        }

                        if (autoSaveFailureData.isEmpty()) {
                            showNotification("Data has been saved successfully.", NotificationStatus.SUCCESS)
                            showAutoSaveErrorDialog = false
                        } else {
                            showNotification(Constants.GENERIC_EXCEPTION_MESSAGE, NotificationStatus.ERROR)
                        }
                        isLoading = false
                    }
                }
                if (isLoading) +"Saving data..." else +"Retry and save data"
            }
        }
    }
}