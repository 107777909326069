package com.ecosave.watch.portal.components.esg.waste

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_306_5 = FC<EsgSubFormComponentProps> { props ->
    props.esgReportState.report?.waste?.let { state ->
        val listName = state::breakdownOfTotalWasteDirectedByCompositionList.name
        val listForTable = state.breakdownOfTotalWasteDirectedByCompositionList

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_306_5
        }

        RomanListWrapper(
            "When compiling the information specified in Disclosure 306-5, the reporting " +
                "organization shall:",
            listOf(
                "exclude effluent, unless required by national legislation to be reported under " +
                    "total waste;",
                "Incineration (without energy recovery);",
                "use 1000 kilograms as the measure for a metric ton."
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        DisclosureSubHeading {
            subHeading = "Total weight of waste directed to disposal in metric tons, and a breakdown of this total " +
                "by composition of the waste."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(listName) }
                items = listForTable
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                    val headers = listOf(
                        "Type of waste",
                        "Waste Streams",
                        "Materials",
                        "Waste Directed to Disposal",
                    )
                    for (inHeader in headers) {
                        TableHeader {
                            header = inHeader
                        }
                    }
                }
            }

            listForTable.forEachIndexed { index, totalWaste ->
                DynamicTableWrapper {
                    key = totalWaste.key
                    deleteRowOrSection = { props.deleteRowOrSection(listName, index) }
                    items = listForTable
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = totalWaste::section3065TypeOfWaste.name
                            value = totalWaste.section3065TypeOfWaste
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGTextFieldWrapper {
                            name = totalWaste::section3065WasteStream.name
                            value = totalWaste.section3065WasteStream
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }
                        ESGTextFieldWrapper {
                            name = totalWaste::section3065Material.name
                            value = totalWaste.section3065Material
                            onChange = { event -> props.onChangeText(event, index, listName) }
                            onBlur = { event -> props.onBlurText(event, index, listName) }
                        }

                        ESGNumberFieldWrapper {
                            name = totalWaste::section3065WasteDirected.name
                            value = totalWaste.section3065WasteDirected
                            onChange = { event -> props.onChangeDecimalNumber(event, index, listName) }
                            onBlur = { event -> props.onBlurDecimalNumber(event, index, listName) }
                        }
                    }

                }
            }
        }

        RomanListWrapper(
            "Total weight of hazardous waste directed to disposal in metric tons, and a breakdown " +
                "of this total by the following disposal operations:",
            listOf(
                "Incineration (with energy recovery);",
                "Incineration (without energy recovery);",
                "Landfilling;",
                "Other Disposal Operations;"
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        RomanListWrapper(
            "Include a breakdown of the total weight in metric tons of hazardous waste" +
                " waste directed to disposal:",
            listOf(
                "Onsite;",
                "Offsite;",
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
            listOf(
                "Disposal Operation", "Onsite", "Offsite", "Total"
            ).forEach {
                TableHeader { header = it }
            }

            listOf(
                Pair(
                    "Incineration (with energy recovery)", listOf(
                        Pair(state::section3065Onsite9.name, state.section3065Onsite9),
                        Pair(state::section3065Offsite9.name, state.section3065Offsite9),
                        Pair(state::section3065Current3.name, state.section3065Current3),
                    )
                ),
                Pair(
                    "Incineration (without energy recovery)", listOf(
                        Pair(state::section3065Onsite10.name, state.section3065Onsite10),
                        Pair(state::section3065Offsite10.name, state.section3065Offsite10),
                        Pair(state::section3065Current7.name, state.section3065Current7),
                    )
                ),
                Pair(
                    "Landfilling", listOf(
                        Pair(state::section3065Onsite11.name, state.section3065Onsite11),
                        Pair(state::section3065Offsite11.name, state.section3065Offsite11),
                        Pair(state::section3065Current10.name, state.section3065Current10),
                    )
                ),
                Pair(
                    "Other Disposal Operations", listOf(
                        Pair(state::section3065Onsite12.name, state.section3065Onsite12),
                        Pair(state::section3065Offsite12.name, state.section3065Offsite12),
                        Pair(state::section3065Current13.name, state.section3065Current13),
                    )
                ),
            ).forEach {
                label { +it.first }
                it.second.forEach {
                    ESGNumberFieldWrapper {
                        name = it.first
                        value = it.second
                        onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
                    }
                }
            }
            //TOTAL
            label { +"Total weight of hazardous waste directed to disposal" }
            label { +"" }
            label { +"" }
            ESGNumberFieldWrapper {
                name = state::section3065Current2.name
                value = state.section3065Current2
                onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
            }
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "The organization can specify types of disposal operations (dumping, open burning," +
                        " or deep well injection).",
                    state::section3065Field2.name,
                    state.section3065Field2
                )
            ),
            props
        )

        RomanListWrapper(
            "Total weight of non-hazardous waste directed to disposal in metric tons, and a breakdown " +
                "of this total by the following disposal operations:",
            listOf(
                "Incineration (with energy recovery);",
                "Incineration (without energy recovery);",
                "Landfilling;",
                "Other Disposal Operations;"
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        RomanListWrapper(
            "Include a breakdown of the total weight in metric tons of non-hazardous" +
                " waste directed to disposal:",
            listOf(
                "Onsite;",
                "Offsite;",
            ),
            ESGCommonStyles.LIST_SUBTITLE.cssClass
        )

        Box {
            className = ESGCommonStyles.FOUR_COLUMN_TABLE.cssClass
            listOf(
                "Disposal Operation", "Onsite", "Offsite", "Total"
            ).forEach {
                TableHeader { header = it }
            }

            listOf(
                Pair(
                    "Incineration (with energy recovery)", listOf(
                        Pair(state::section3065Onsite1.name, state.section3065Onsite1),
                        Pair(state::section3065Offsite1.name, state.section3065Offsite1),
                        Pair(state::section3065Current17.name, state.section3065Current17),
                    )
                ),
                Pair(
                    "Incineration (without energy recovery)", listOf(
                        Pair(state::section3065Onsite2.name, state.section3065Onsite2),
                        Pair(state::section3065Offsite2.name, state.section3065Offsite2),
                        Pair(state::section3065Current20.name, state.section3065Current20),
                    )
                ),
                Pair(
                    "Landfilling", listOf(
                        Pair(state::section3065Onsite3.name, state.section3065Onsite3),
                        Pair(state::section3065Offsite3.name, state.section3065Offsite3),
                        Pair(state::section3065Current23.name, state.section3065Current23),
                    )
                ),
                Pair(
                    "Other Disposal Operations", listOf(
                        Pair(state::section3065Onsite4.name, state.section3065Onsite4),
                        Pair(state::section3065Offsite4.name, state.section3065Offsite4),
                        Pair(state::section3065Current26.name, state.section3065Current26),
                    )
                ),
            ).forEach {
                label { +it.first }
                it.second.forEach {
                    ESGNumberFieldWrapper {
                        name = it.first
                        value = it.second
                        onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                        onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
                    }
                }
            }
            //TOTAL ROW
            label { +"Total weight of non-hazardous waste directed to disposal" }
            label { +""}
            label { +""}
            ESGNumberFieldWrapper {
                name = state::section3065Current16.name
                value =state.section3065Current16
                onChange = { event -> props.onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> props.onBlurDecimalNumber(event, null, null) }
            }
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "The organization can specify types of disposal operations (dumping, open burning," +
                        " or deep well injection).",
                    state::section3065Field3.name,
                    state.section3065Field3
                ),
                TextAreaFieldMapper(
                    "Provide contextual information necessary to understand the data and how the data has been " +
                        "compiled.",
                    state::section3065Field1.name,
                    state.section3065Field1
                )
            ),
            props
        )

    }
}