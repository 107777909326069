package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.common.RomanListWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DecimalFieldMapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import react.FC

val GRI_305_6 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.emissions?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_305_6
        }

        RomanListWrapper(
            title = "When compiling the information specified in Disclosure 305-6, the reporting organization shall:",
            list = listOf(
                "calculate the production of ODS as the amount of ODS produced, minus the amount destroyed by " +
                    "approved technologies, and minus the amount entirely used as feedstock in the manufacture " +
                    "of other chemicals (Production ODS = ODS produced - ODS Destroyed by approved technologies - " +
                    "ODS entirely used as feedback in the manufacture of other chemicals).;",

                "exclude ODS recycled and reused" ,
            ),
            inClassName = ESGCommonStyles.LIST_SUBTITLE.cssClass
        )
        ESGBaseFormBuilder(
            listOf(
                DecimalFieldMapper(
                    "ODS Produced",
                    state::section3056Current1.name,
                    state.section3056Current1
                ),
                DecimalFieldMapper(
                    "ODS destroyed by approved technologies",
                    state::section3056Current2.name,
                    state.section3056Current2
                ),
                DecimalFieldMapper(
                    "ODS entirely used as feedstock in the manufacture of other chemicals",
                    state::section3056Current3.name,
                    state.section3056Current3
                ),
                DecimalFieldMapper(
                    "Production of ODS in metric tons of CFC-11 ",
                    state::section3056Current4.name,
                    state.section3056Current4
                ),
                DecimalFieldMapper(
                    "Imports of ODS in metric tons of CFC-11 ",
                    state::section3056Field1.name,
                    state.section3056Field1
                ),
                DecimalFieldMapper(
                    "Exports of ODS in metric tons of CFC-11 ",
                    state::section3056Field2.name,
                    state.section3056Field2
                ),
                TextAreaFieldMapper(
                    "What substances included in the calculation",
                    state::section3056Field3.name,
                    state.section3056Field3
                ),
                TextAreaFieldMapper(
                    "What source of emission factors used",
                    state::section3056Field4.name,
                    state.section3056Field4
                ),
                TextAreaFieldMapper(
                    "What standards, methodologies, assumptions, and/or calculation tools used",
                    state::section3056Field5.name,
                    state.section3056Field5
                ),
            ),
            props
        )


    }

}

