package com.ecosave.watch.portal.components.esg.employment

import com.ecosave.watch.portal.components.common.AlphaListWrapper
import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px

val GRI_401_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.employment?.let { state ->
        val benefitsListName = state::benefitsList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_401_2
        }

        DisclosureSubHeading {
            this.subHeading = "Benefits that are standard for full-time employees that are not " +
                    "provided to temporary or part-time employees"
        }

        AlphaListWrapper(
            title = "Benefits which are standard for full-time employees of the organization but are not " +
                    "provided to temporary or part-time employees, by significant locations of operation. " +
                    "These include, as a minimum:",
            list = listOf(
                "Life Insurance",
                "Health Care",
                "Disability and Invalidity Coverage",
                "Parental Leave",
                "Retirement provision",
                "Stock ownership",
                "others"
            ),
        )

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            Box {
                className = ESGCommonStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRowOrSection = {
                        props.addRowOrSection(benefitsListName)
                    }
                    items = state.benefitsList
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowOrSectionBtnText = "Add a new benefits details"
                    Box {
                        sx {
                            padding = 20.px
                        }
                        DisclosureSubHeading {
                            subHeading = "For each Significant Location of Operation please specify:"
                        }
                    }
                }
            }

            state.benefitsList.forEachIndexed { index, employeeBenefits ->
                DynamicTableWrapper {
                    key = employeeBenefits.key
                    deleteRowOrSection = { props.deleteRowOrSection(benefitsListName, index) }
                    items = state.newHiresTurnoverList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.LIST
                    Box {
                        className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                        DisclosureSubHeading {
                            subHeading = "Benefits Details for Significant Location of Operation  ${index + 1}"
                        }
                        label {
                            +"Significant Location of Operations"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::location.name
                            value = employeeBenefits.location
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }

                        label {
                            +"Life Insurance"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::lifeInsurance.name
                            value = employeeBenefits.lifeInsurance
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }
                        label {
                            +"Health Care"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::healthCare.name
                            value = employeeBenefits.healthCare
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }
                        label {
                            +"Disability and Invalidity Coverage"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::disability.name
                            value = employeeBenefits.disability
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }
                        label {
                            +"Parental Leave"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::parentalLeave.name
                            value = employeeBenefits.parentalLeave
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }
                        label {
                            +"Retirement Provision"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::retirementProvision.name
                            value = employeeBenefits.retirementProvision
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }
                        label {
                            +"Stock Ownership"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::stockOwnership.name
                            value = employeeBenefits.stockOwnership
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }
                        label {
                            +"Other Benefits"
                        }
                        ESGTextAreaFieldWrapper {
                            name = employeeBenefits::otherBefenits.name
                            value = employeeBenefits.otherBefenits
                            onChange = { event -> onChangeTextArea(event, index, benefitsListName) }
                            onBlur = { event -> onBlurTextArea(event, index, benefitsListName) }
                            minimumRows = 2
                        }
                    }
                }
            }
        }
    }
}
