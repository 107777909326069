package com.ecosave.watch.portal.components.esg.indirecteconomicimpacts

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px


val Disclosure_203_1 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.indirectEconomicImpacts?.let { state ->
        val infrastructureInvestmentListName = state::infrastureInvestmentList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_203_1
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            Box {
                className = ESGCommonStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRowOrSection = { props.addRowOrSection(infrastructureInvestmentListName) }
                    items = state.infrastureInvestmentList
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowOrSectionBtnText = "Add a new section"
                    Box {
                        sx {
                            padding = 20.px
                        }
                        DisclosureSubHeading {
                            subHeading = "Investment/Service"
                        }
                    }
                }
            }

            state.infrastureInvestmentList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(infrastructureInvestmentListName, index)
                    }
                    items = state.infrastureInvestmentList
                    action = DynamicTableAction.DELETE
                    Box {
                        className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                        DisclosureSubHeading {
                            subHeading = "Investment/Service ${index + 1}"
                        }
                        Box {
                            label {
                                +"Name of investment/service"
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2031Part1A1.name
                                value = it.section2031Part1A1
                                onChange = { event ->
                                    onChangeTextArea(event, index, infrastructureInvestmentListName)
                                }
                                onBlur = { event ->
                                    onBlurTextArea(event, index, infrastructureInvestmentListName)
                                }
                            }
                        }
                        Box {
                            label {
                                +"Extent of development of significant infrastructure investment and service supported."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2031Part1A2.name
                                value = it.section2031Part1A2
                                onChange = { event ->
                                    onChangeTextArea(event, index, infrastructureInvestmentListName)
                                }
                                onBlur = { event ->
                                    onBlurTextArea(event, index, infrastructureInvestmentListName)
                                }
                            }
                        }
                        Box {
                            label {
                                +"Current or expected impacts on communities and local economies, including positive and negative impacts where relevant."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2031Part1A3.name
                                value = it.section2031Part1A3
                                onChange = { event ->
                                    onChangeTextArea(event, index, infrastructureInvestmentListName)
                                }
                                onBlur = { event ->
                                    onBlurTextArea(event, index, infrastructureInvestmentListName)
                                }
                            }
                        }
                        Box {
                            label {
                                +"Whether the investment and service are commercial, in-kind, or pro bono engagement."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2031Part1A4.name
                                value = it.section2031Part1A4
                                onChange = { event ->
                                    onChangeTextArea(event, index, infrastructureInvestmentListName)
                                }
                                onBlur = { event ->
                                    onBlurTextArea(event, index, infrastructureInvestmentListName)
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}