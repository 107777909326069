package com.ecosave.watch.portal.styles.esg

import web.cssom.ClassName

enum class ESGCommonStyles(val cssClass: ClassName) {
    MAIN_ESG_FORM(ClassName("main-esg-form")),
    ESG_CALENDAR_MAIN(ClassName("esg-calendar-main")),
    ESG_CALENDAR_TEXT_FONT(ClassName("esg-calendar-text-font")),
    ESG_CALENDAR_MAIN_FONT(ClassName("esg-calendar-main-font")),
    TOC_ICON_SECOND(ClassName("toc-icon-second")),
    ESG_CIRCULAR_PROGRESS(ClassName("esg-circular-progress")),
    SECTION_MAIN_LAYOUT(ClassName("section-main-layout")),
    TABLE_WRAPPER(ClassName("table-wrapper")),
    STICKY_HEADER(ClassName("sticky-header")),
    GRID_WRAPPER(ClassName("grid-wrapper")),
    TABLE_GRID_WRAPPER(ClassName("table-wrapper grid-wrapper")),
    TWO_COLUMN_TABLE(ClassName("two-column-table grid-wrapper ")),
    TWO_COLUMN_TABLE_4_8(ClassName("two-column-table-4-8 grid-wrapper ")),
    TWO_COLUMN_TABLE_8_4(ClassName("two-column-table-8-4 grid-wrapper ")),
    THREE_COLUMN_TABLE(ClassName("three-column-table grid-wrapper")),
    THREE_COLUMN_TABLE_8_2_2(ClassName("three-column-table-8-2-2 grid-wrapper")),
    FOUR_COLUMN_TABLE(ClassName("four-column-table grid-wrapper")),
    FOUR_COLUMN_TABLE_6_2_2_2(ClassName("four-column-table-6-2-2-2 grid-wrapper")),
    SIX_COLUMN_TABLE(ClassName("six-column-table grid-wrapper")),
    SEVEN_COLUMN_TABLE(ClassName("seven-column-table")),
    DYNAMIC_TABLE_SECTION_LAYOUT(ClassName("dynamic-table-section-layout")),
    LEFT_INDENT(ClassName("left-indent")),
    LIST_SUBTITLE(ClassName("list-subtitle")),
}

enum class EconomicPerformanceStyles(val cssClass: ClassName) {
    SECTION_201_1_TABLE(ClassName("section_201_1_table grid-wrapper")),
    SECTION_201_4_TABLE(ClassName("section_201_4_table grid-wrapper")),
    SECTION_201_4_TABLE_2(ClassName("section_201_4_table_2 grid-wrapper")),
}

enum class ESGFormStyles(val cssClass: ClassName) {
    ADD_BUTTON(ClassName("esg-table-add-button")),
    SECTION_415_1_TABLE(ClassName("section_415_1_table grid-wrapper")),
    DISCLOSURE_2_7_SECOND_TABLE(ClassName("disclosure-2-7-second-table grid-wrapper")),
}


