package com.ecosave.watch.portal.helpers.esg

enum class GriDisclosureTitles(val disclosureTitle: String, val esgSection: EsgSection) {
    GRI_2_1("2-1: ORGANIZATIONAL DETAILS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_2("2-2 ENTITIES INCLUDED IN THE ORGANIZATION’S SUSTAINABILITY REPORTING", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_3("2-3 REPORTING PERIOD, FREQUENCY AND CONTACT POINT", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_4("2-4 RESTATEMENTS OF INFORMATION", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_5("2-5 EXTERNAL ASSURANCE", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_6("2-6 ACTIVITIES, VALUE CHAIN, AND OTHER BUSINESS RELATIONSHIPS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_7("2-7 EMPLOYEES", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_8("2-8 WORKERS WHO ARE NOT EMPLOYEES", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_9("2-9 GOVERNANCE STRUCTURE AND COMPOSITION", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_10("2-10 NOMINATION AND SELECTION OF THE HIGHEST GOVERNANCE BODY", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_11("2-11 CHAIR OF THE HIGHEST GOVERNANCE BODY", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_12(
        "2-12: ROLE OF THE HIGHEST GOVERNANCE BODY IN OVERSEEING THE MANAGEMENT OF IMPACTS",
        EsgSection.GENERAL_DISCLOSURES
    ),
    GRI_2_13("2-13 DELEGATION OF RESPONSIBILITY FOR MANAGING IMPACTS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_14("2-14 ROLE OF THE HIGHEST GOVERNANCE BODY IN SUSTAINABILITY REPORTING", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_15("2-15 CONFLICTS OF INTEREST", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_16("2-16 COMMUNICATION OF CRITICAL CONCERNS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_17("2-17 COLLECTIVE KNOWLEDGE OF THE HIGHEST GOVERNANCE BODY", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_18("2-18 EVALUATION OF THE PERFORMANCE OF THE HIGHEST GOVERNANCE BODY", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_19("2-19 REMUNERATION POLICIES", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_20("2-20 PROCESS TO DETERMINE REMUNERATION", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_21("2-21 ANNUAL TOTAL COMPENSATION RATIO", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_22("2-22 STATEMENT ON SUSTAINABLE DEVELOPMENT STRATEGY", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_23("2-23 POLICY COMMITMENTS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_24("2-24 EMBEDDING POLICY COMMITMENTS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_25("2-25 PROCESSES TO REMEDIATE NEGATIVE IMPACTS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_26("2-26 MECHANISMS FOR SEEKING ADVICE AND RAISING CONCERNS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_27("2-27 COMPLIANCE WITH LAWS AND REGULATIONS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_28("2-28 MEMBERSHIP ASSOCIATIONS", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_29("2-29 APPROACH TO STAKEHOLDER ENGAGEMENT", EsgSection.GENERAL_DISCLOSURES),
    GRI_2_30("2-30 COLLECTIVE BARGAINING AGREEMENTS", EsgSection.GENERAL_DISCLOSURES),
    GRI_3_1("3-1 PROCESS TO DETERMINE MATERIAL TOPICS", EsgSection.MATERIAL_TOPICS),
    GRI_3_2("3-2 LIST OF MATERIAL TOPICS", EsgSection.MATERIAL_TOPICS),
    GRI_3_3("3-3 MANAGEMENT OF MATERIAL TOPICS", EsgSection.MATERIAL_TOPICS),
    GRI_201_1("201-1 DIRECT ECONOMIC VALUE GENERATED AND DISTRIBUTED (EVG&D)", EsgSection.ECONOMIC_PERFORMANCE),
    GRI_201_2(
        "201-2 FINANCIAL IMPLICATIONS AND OTHER RISKS AND OPPORTUNITIES DUE TO CLIMATE CHANGE",
        EsgSection.ECONOMIC_PERFORMANCE
    ),
    GRI_201_3("201-3 DEFINED BENEFIT PLAN OBLIGATIONS AND OTHER RETIREMENT PLANS", EsgSection.ECONOMIC_PERFORMANCE),
    GRI_201_4("201-4 FINANCIAL ASSISTANCE RECEIVED FROM GOVERNMENT", EsgSection.ECONOMIC_PERFORMANCE),
    GRI_202_1(
        "202-1 RATIOS OF STANDARD ENTRY LEVEL WAGE BY GENDER COMPARED TO LOCAL MINIMUM WAGE",
        EsgSection.MARKET_PRESENCE
    ),
    GRI_202_2("202-2 PROPORTION OF SENIOR MANAGEMENT HIRED FROM THE LOCAL COMMUNITY", EsgSection.MARKET_PRESENCE),
    GRI_203_1("203-1 INFRASTRUCTURE INVESTMENTS AND SERVICES SUPPORTED", EsgSection.INDIRECT_ECONOMIC_IMPACTS),
    GRI_203_2("203-2 SIGNIFICANT INDIRECT ECONOMIC IMPACTS", EsgSection.INDIRECT_ECONOMIC_IMPACTS),
    GRI_204_1("204-1 PROPORTION OF SPENDING ON LOCAL SUPPLIERS", EsgSection.PROCUREMENT_PRACTICES),
    GRI_205_1("205-1 OPERATION ASSESSED FOR RISKS RELATED TO CORRUPTION", EsgSection.ANTI_CORRUPTION),
    GRI_205_2(
        "205-2 COMMUNICATION AND TRAINING ABOUT ANTI-CORRUPTION POLICIES AND PROCEDURES",
        EsgSection.ANTI_CORRUPTION
    ),
    GRI_205_3("205-3 CONFIRMED INCIDENTS OF CORRUPTION AND ACTIONS TAKEN", EsgSection.ANTI_CORRUPTION),
    GRI_206_1(
        "206-1 LEGAL ACTIONS FOR ANTI-COMPETITIVE BEHAVIOR, ANTI-TRUST, AND MONOPOLY PRACTICES",
        EsgSection.ANTI_COMPETITIVE_BEHAVIOR
    ),
    GRI_207_1("207-1 APPROACH TO TAX", EsgSection.TAX),
    GRI_207_2("207-2 TAX GOVERNANCE, CONTROL, AND RISK MANAGEMENT", EsgSection.TAX),
    GRI_207_3("207-3 STAKEHOLDER ENGAGEMENT AND MANAGEMENT OF CONCERNS RELATED TO TAX", EsgSection.TAX),
    GRI_207_4("207-4 COUNTRY-BY-COUNTRY REPORTING", EsgSection.TAX),
    GRI_301_1("301-1 MATERIALS USED BY WEIGHT OR VOLUME", EsgSection.MATERIALS),
    GRI_301_2("301-2 RECYCLED INPUT MATERIALS", EsgSection.MATERIALS),
    GRI_301_3("301-3 RECLAIMED PRODUCTS AND THEIR PACKAGING MATERIALS", EsgSection.MATERIALS),
    GRI_302_1("302-1 ENERGY CONSUMPTION WITHIN THE ORGANIZATION", EsgSection.ENERGY),
    GRI_302_2("302-2 ENERGY CONSUMPTION OUTSIDE OF THE ORGANIZATION", EsgSection.ENERGY),
    GRI_302_3("302-3 ENERGY INTENSITY", EsgSection.ENERGY),
    GRI_302_4(
        "302-4 REDUCTION OF ENERGY CONSUMPTION (Direct Result of Conservation and Efficiency Initiatives",
        EsgSection.ENERGY
    ),
    GRI_302_5("302-5 REDUCTION IN ENERGY REQUIREMENTS OF PRODUCTS AND SERVICES", EsgSection.ENERGY),
    GRI_303_1("303-1 INTERACTIONS WITH WATER AS A SHARED RESOURCE", EsgSection.WATER_AND_EFFLUENTS),
    GRI_303_2("303-2 MANAGEMENT OF WATER DISCHARGE-RELATED IMPACTS", EsgSection.WATER_AND_EFFLUENTS),
    GRI_303_3("303-3 WATER WITHDRAWAL", EsgSection.WATER_AND_EFFLUENTS),
    GRI_303_4("303-4 WATER DISCHARGE", EsgSection.WATER_AND_EFFLUENTS),
    GRI_303_5("303-5 WATER CONSUMPTION", EsgSection.WATER_AND_EFFLUENTS),
    GRI_304_1(
        "304-1 OPERATIONAL SITES OWNED, LEASED, MANAGED IN, OR ADJACENT TO, PROTECTED AREAS AND AREAS OF HIGH BIODIVERSITY VALUE OUTSIDE PROTECTED AREAS",
        EsgSection.BIODIVERSITY
    ),
    GRI_304_2(
        "304-2 SIGNIFICANT IMPACTS OF ACTIVITIES, PRODUCTS AND SERVICES ON BIODIVERSITY",
        EsgSection.BIODIVERSITY
    ),
    GRI_304_3("304-3 HABITATS PROTECTED OR RESTORED", EsgSection.BIODIVERSITY),
    GRI_304_4(
        "304-4 IUCN RED LIST SPECIES AND NATIONAL CONSERVATION LIST SPECIES WITH HABITATS IN AREAS AFFECTED BY OPERATIONS",
        EsgSection.BIODIVERSITY
    ),
    GRI_305_1("305-1 DIRECT (SCOPE 1) GHG EMISSIONS", EsgSection.EMISSIONS),
    GRI_305_2("305-2 ENERGY INDIRECT (SCOPE 2) GHG EMISSIONS", EsgSection.EMISSIONS),
    GRI_305_3("305-3 OTHER INDIRECT (SCOPE 3) GHG EMISSIONS", EsgSection.EMISSIONS),
    GRI_305_4("305-4 GHG EMISSIONS INTENSITY", EsgSection.EMISSIONS),
    GRI_305_5("305-5 REDUCTION OF GHG EMISSIONS", EsgSection.EMISSIONS),
    GRI_305_6("305-6 EMISSIONS OF OZONE-DEPLETING SUBSTANCES (ODS)", EsgSection.EMISSIONS),
    GRI_305_7(
        "305-7 NITROGEN OXIDES (NOx), SULFUR OXIDES (SOx), AND OTHER SIGNIFICANT AIR EMISSIONS",
        EsgSection.EMISSIONS
    ),
    GRI_306_1("306-1 WASTE GENERATION AND SIGNIFICANT WASTE-RELATED IMPACTS", EsgSection.WASTE),
    GRI_306_2("306-2 MANAGEMENT OF SIGNIFICANT WASTE-RELATED IMPACTS", EsgSection.WASTE),
    GRI_306_3("306-3 WASTE GENERATED", EsgSection.WASTE),
    GRI_306_4("306-4 WASTE DIVERTED FROM DISPOSAL", EsgSection.WASTE),
    GRI_306_5("306-5 WASTE DIRECTED TO DISPOSAL", EsgSection.WASTE),
    GRI_308_1(
        "308-1 NEW SUPPLIERS THAT WERE SCREENED USING ENVIRONMENTAL CRITERIA",
        EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT
    ),
    GRI_308_2(
        "308-2 NEGATIVE ENVIRONMENTAL IMPACTS IN THE SUPPLY CHAIN AND ACTIONS TAKEN",
        EsgSection.SUPPLIER_ENVIRONMENTAL_ASSESSMENT
    ),
    GRI_401_1("401-1 NEW EMPLOYEE HIRES AND EMPLOYEE TURNOVER", EsgSection.EMPLOYMENT),
    GRI_401_2(
        "401-2 BENEFITS PROVIDED TO FULL-TIME EMPLOYEES THAT ARE NOT PROVIDED TO TEMPORARY OR PART-TIME EMPLOYEES",
        EsgSection.EMPLOYMENT
    ),
    GRI_401_3("401-3 PARENTAL LEAVE", EsgSection.EMPLOYMENT),
    GRI_402_1("402-1 MINIMUM NOTICE PERIODS REGARDING OPERATIONAL CHANGES", EsgSection.MANAGEMENT_RELATIONS),
    GRI_403_1("403-1 OCCUPATIONAL HEALTH AND SAFETY MANAGEMENT SYSTEM", EsgSection.OCCUPATION_HEALTH_AND_SAFETY),
    GRI_403_2(
        "403-2 HAZARD IDENTIFICATION, RISK ASSESSMENT, AND INCIDENT INVESTIGATION",
        EsgSection.OCCUPATION_HEALTH_AND_SAFETY
    ),
    GRI_403_3("403-3 OCCUPATION HEALTH SERVICES", EsgSection.OCCUPATION_HEALTH_AND_SAFETY),
    GRI_403_4(
        "403-4 WORKER PARTICIPATION, CONSULTATION, AND COMMUNICATION ON OCCUPATIONAL HEALTH AND SAFETY",
        EsgSection.OCCUPATION_HEALTH_AND_SAFETY
    ),
    GRI_403_5("403-5 WORKER TRAINING ON OCCUPATIONAL HEALTH AND SAFETY", EsgSection.OCCUPATION_HEALTH_AND_SAFETY),
    GRI_403_6("403-6 PROMOTION OF WORKER HEALTH", EsgSection.OCCUPATION_HEALTH_AND_SAFETY),
    GRI_403_7(
        "403-7 PREVENTION AND MITIGATION OF OCCUPATIONAL HEALTH AND SAFETY IMPACTS DIRECTLY LINKED BY BUSINESS RELATIONSHIPS",
        EsgSection.OCCUPATION_HEALTH_AND_SAFETY
    ),
    GRI_403_8(
        "403-8 WORKERS COVERED BY AN OCCUPATIONAL HEALTH AND SAFETY MANAGEMENT SYSTEM",
        EsgSection.OCCUPATION_HEALTH_AND_SAFETY
    ),
    GRI_403_9("403-9 WORK-RELATED INJURIES", EsgSection.OCCUPATION_HEALTH_AND_SAFETY),
    GRI_403_10("403-10 WORK-RELATED ILL HEALTH", EsgSection.OCCUPATION_HEALTH_AND_SAFETY),
    GRI_404_1("404-1 AVERAGE HOURS OF TRAINING PER YEAR PER EMPLOYEE", EsgSection.TRAINING_AND_EDUCATION),
    GRI_404_2(
        "404-2 PROGRAMS FOR UPGRADING EMPLOYEE SKILLS AND TRANSITION ASSISTANCE PROGRAMS",
        EsgSection.TRAINING_AND_EDUCATION
    ),
    GRI_404_3(
        "404-3 PERCENTAGE OF EMPLOYEES RECEIVING REGULAR PERFORMANCE AND CAREER DEVELOPMENT REVIEWS",
        EsgSection.TRAINING_AND_EDUCATION
    ),
    GRI_405_1(
        "405-1 DIVERSITY OF GOVERNANCE BODIES AND EMPLOYEES",
        EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY
    ),
    GRI_405_2(
        "405-2 RATIO OF BASIC SALARY AND REMUNERATION OF WOMEN TO MEN",
        EsgSection.DIVERSITY_AND_EQUAL_OPPORTUNITY
    ),
    GRI_406_1(
        "406-1 INCIDENTS OF DISCRIMINATION AND CORRECTIVE ACTIONS TAKEN",
        EsgSection.NON_DISCRIMINATION
    ),
    GRI_407_1(
        "407-1 OPERATIONS AND SUPPLIERS IN WHICH THE RIGHT TO FREEDOM OF ASSOCIATION AND COLLECTIVE BARGAINING MAY BE AT RISK",
        EsgSection.FREEDOM_OF_ASSOCIATION_AND_COLLECTIVE_BARGAINING
    ),
    GRI_408_1(
        "408-1 OPERATIONS AND SUPPLIERS AT SIGNIFICANT RISK FOR INCIDENTS OF CHILD LABOR",
        EsgSection.CHILD_LABOR
    ),
    GRI_409_1(
        "409-1 OPERATIONS AND SUPPLIER AT SIGNIFICANT RISK FOR INCIDENTS OF FORCED OR COMPULSORY LABOR",
        EsgSection.FORCED_OR_COMPULSORY_LABOR
    ),
    GRI_410_1(
        "410-1 SECURITY PERSONNEL TRAINED IN HUMAN RIGHTS POLICIES OR PROCEDURES",
        EsgSection.SECURITY_PRACTICES
    ),
    GRI_413_1(
        "413-1 OPERATIONS WITH LOCAL COMMUNITY ENGAGEMENT, IMPACT ASSESSMENTS, AND DEVELOPMENT PROGRAMS",
        EsgSection.LOCAL_COMMUNITY
    ),
    GRI_413_2(
        "413-2 OPERATIONS WITH SIGNIFICANT ACTUAL AND POTENTIAL NEGATIVE IMPACTS ON LOCAL COMMUNITIES",
        EsgSection.LOCAL_COMMUNITY
    ),
    GRI_411_1(
        "411-1 INCIDENTS OF VIOLATIONS INVOLVING RIGHTS OF INDIGENOUS PEOPLES",
        EsgSection.RIGHTS_OF_INDIGENOUS_PEOPLES
    ),
    GRI_414_1(
        "414-1 NEW SUPPLIERS THAT WERE SCREENED USING SOCIAL CRITERIA",
        EsgSection.SUPPLIER_SOCIAL_ASSESSMENT
    ),
    GRI_414_2(
        "414-2 NEGATIVE SOCIAL IMPACTS IN THE SUPPLY CHAIN AND ACTIONS TAKEN",
        EsgSection.SUPPLIER_SOCIAL_ASSESSMENT
    ),
    GRI_415_1(
        "415-1 POLITICAL CONTRIBUTIONS",
        EsgSection.PUBLIC_POLICY
    ),
    GRI_416_1(
        "416-1 ASSESSMENT OF THE HEALTH AND SAFETY IMPACTS OF PRODUCT AND SERVICE CATEGORIES",
        EsgSection.CUSTOMER_HEALTH_AND_SAFETY
    ),
    GRI_416_2(
        "416-2 INCIDENTS OF NON-COMPLIANCE CONCERNING THE HEALTH AND SAFETY IMPACTS OF PRODUCTS AND SERVICES",
        EsgSection.CUSTOMER_HEALTH_AND_SAFETY
    ),
    GRI_417_1("417-1 REQUIREMENTS FOR PRODUCT AND SERVICE INFORMATION AND LABELING", EsgSection.MARKETING_AND_LABELING),
    GRI_417_2(
        "417-2 INCIDENTS OF NON-COMPLIANCE CONCERNING PRODUCT AND SERVICE INFORMATION AND LABELING",
        EsgSection.MARKETING_AND_LABELING
    ),
    GRI_417_3(
        "417-3 INCIDENTS OF NON-COMPLIANCE CONCERNING MARKETING COMMUNICATIONS",
        EsgSection.MARKETING_AND_LABELING
    ),
    GRI_418_1(
        "418-1 SUBSTANTIATED COMPLAINTS CONCERNING BREACHES OF CUSTOMER PRIVACY AND LOSSES OF CUSTOMER DATA",
        EsgSection.CUSTOMER_PRIVACY
    );


    companion object {
        @OptIn(ExperimentalStdlibApi::class)
        fun getDisclosuresList(esgSection: EsgSection) =
            entries.filter {
                it.esgSection == esgSection
            }
    }
}