package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class MaterialsState(
    var section3011A1: String? = null,
    var section3011A2: String? = null,

    var section3012Current1: Double? = null,

    var section3013Current1: String? = null,
    var section3013Field1: String? = null,

    var section3_3_301A1: String? = null,
    var section3_3_301A2: String? = null,
    var section3_3_301A3: String? = null,
    var section3_3_301A4: String? = null,
    var section3_3_301A5: String? = null,
    var section3_3_301A6: String? = null
)