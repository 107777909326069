package com.ecosave.watch.portal.components.esg.biodiversity

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.dom.onChange
import web.cssom.px

val GRI_304_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.biodiversity?.let { state ->
        val listName = state::significantImpactsBiodiversityList.name
        val listNameTwo = state::significantDirectAndIndirectList.name
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_304_2
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            Box {
                className = ESGCommonStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRowOrSection = {
                        props.addRowOrSection(listName)
                    }
                    items = state.significantImpactsBiodiversityList
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowOrSectionBtnText = "Add a new significant direct and indirect impacts on biodiversity details"
                    Box {
                        sx {
                            padding = 20.px
                        }
                        DisclosureSubHeading {
                            subHeading =
                                "Nature of significant direct and indirect impacts on biodiversity with reference to one or " +
                                        "more of the following:"
                        }
                    }
                }
            }

            state.significantImpactsBiodiversityList.forEachIndexed { index, significantImpactsBiodiversity ->
                DynamicTableWrapper {
                    key = significantImpactsBiodiversity.key
                    deleteRowOrSection = { props.deleteRowOrSection(listName, index) }
                    items = state.biodiversityGeographicalLocationList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.LIST
                    Box {
                        className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                        DisclosureSubHeading {
                            subHeading = "Impacts on biodiversity details and  ${index + 1}"
                        }

                        listOf(
                            TextAreaFieldMapper(
                                "Construction or use of manufacturing plants, mines, and transport infrastructure",
                                significantImpactsBiodiversity::section3042A1.name,
                                significantImpactsBiodiversity.section3042A1
                            ),
                            TextAreaFieldMapper(
                                "Pollution (introduction of substances that do not naturally occur in the habitat " +
                                        "from point and non-point sources)",
                                significantImpactsBiodiversity::section3042A2.name,
                                significantImpactsBiodiversity.section3042A2
                            ),
                            TextAreaFieldMapper(
                                "Introduction of invasive species, pests, and pathogens",
                                significantImpactsBiodiversity::section3042A3.name,
                                significantImpactsBiodiversity.section3042A3
                            ),
                            TextAreaFieldMapper(
                                "Reduction of species",
                                significantImpactsBiodiversity::section3042A4.name,
                                significantImpactsBiodiversity.section3042A4
                            ),
                            TextAreaFieldMapper(
                                "Habitat conversion",
                                significantImpactsBiodiversity::section3042A5.name,
                                significantImpactsBiodiversity.section3042A5
                            ),
                            TextAreaFieldMapper(
                                "Changes in ecological processes outside the natural range of variation (such as " +
                                        "salinity or changes in groundwater level).",
                                significantImpactsBiodiversity::section3042A6.name,
                                significantImpactsBiodiversity.section3042A6
                            )
                        ).forEach { item ->
                            ReactHTML.label {
                                +item.label.mainHeader
                            }
                            ESGTextAreaFieldWrapper {
                                name = item.name
                                value = item.value
                                onChange = { event -> onChangeTextArea(event, index, listName) }
                                onBlur = { event -> onBlurTextArea(event, index, listName) }
                                minimumRows = 2
                            }
                        }
                    }
                }
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            Box {
                className = ESGCommonStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRowOrSection = {
                        props.addRowOrSection(listNameTwo)
                    }
                    items = state.significantImpactsBiodiversityList
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowOrSectionBtnText = "Add a Significant direct and indirect positive and negative impacts "
                    Box {
                        sx {
                            padding = 20.px
                        }
                        DisclosureSubHeading {
                            subHeading = "Significant direct and indirect positive and negative impacts with reference to" +
                                    " the following:"
                        }
                    }
                }
            }

            state.significantDirectAndIndirectList.forEachIndexed { index, significantDirectAndIndirectItem ->
                DynamicTableWrapper {
                    key = significantDirectAndIndirectItem.key
                    deleteRowOrSection = { props.deleteRowOrSection(listNameTwo, index) }
                    items = state.biodiversityGeographicalLocationList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.LIST
                    Box {
                        className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                        DisclosureSubHeading {
                            subHeading = "Direct and indirect positive and negative impacts  ${index + 1}"
                        }

                        listOf(
                            TextAreaFieldMapper(
                                "Species affected",
                                significantDirectAndIndirectItem::section3042A8.name,
                                significantDirectAndIndirectItem.section3042A8
                            ),
                            TextAreaFieldMapper(
                                "Extent of areas impacted",
                                significantDirectAndIndirectItem::section3042A9.name,
                                significantDirectAndIndirectItem.section3042A9
                            ),
                            TextAreaFieldMapper(
                                "Duration of impacts",
                                significantDirectAndIndirectItem::section3042A10.name,
                                significantDirectAndIndirectItem.section3042A10
                            ),
                            TextAreaFieldMapper(
                                "Reversibility or irreversibility of the impacts.",
                                significantDirectAndIndirectItem::section3042A11.name,
                                significantDirectAndIndirectItem.section3042A11
                            ),
                        ).forEach { item ->
                            ReactHTML.label {
                                +item.label.mainHeader
                            }
                            ESGTextAreaFieldWrapper {
                                name = item.name
                                value = item.value
                                onChange = { event -> onChangeTextArea(event, index, listNameTwo) }
                                onBlur = { event -> onBlurTextArea(event, index, listNameTwo) }
                                minimumRows = 2
                            }
                        }
                    }
                }
            }
        }
    }
}

