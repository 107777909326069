package com.ecosave.watch.portal.components.esg.marketpresence

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_202_1 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.marketPresence?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_202_1
        }

        Box {
            label {
                +"When a significant proportion of employees are compensated based on wages subject to minimum wage rules, report the relevant ratio of the entry level wage by gender at significant locations of operation to the minimum wage."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2021A1.name
                value = state.section2021A1 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"When a significant proportion of other workers (excluding employees) performing the organization’s activities are compensated based on wages subject to minimum wage rules, describe the actions taken to determine whether these workers are paid above the minimum wage."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2021A2.name
                value = state.section2021A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"Whether a local minimum wage is absent or variable at significant locations of operation, by gender. In circumstances in which different minimums can be used as a reference, report which minimum wage is being used."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2021A3.name
                value = state.section2021A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"The definition used for ‘significant locations of operation’."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2021A4.name
                value = state.section2021A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}