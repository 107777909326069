package com.ecosave.watch.portal.components.esg.tax

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import react.FC

val Disclosure_207_2 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.tax?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_207_2
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "A description of the tax governance and control framework, including:",
                    state::section2072A1.name,
                    state.section2072A1,
                    listHeaders = listOf(
                        "the governance body or executive-level position within the organization accountable for compliance with the tax strategy;",
                        "how the approach to tax is embedded within the organization;",
                        "the approach to tax risks, including how risks are identified, managed, and monitored;",
                        "how compliance with the tax governance and control framework is evaluated."
                    )
                ),
                TextAreaFieldMapper(
                    "A description of the mechanisms to raise concerns about the organization’s business conduct and the organization’s integrity in relation to tax.",
                    state::section2072A2.name,
                    state.section2072A2
                ),
                TextAreaFieldMapper(
                    "A description of the assurance process for disclosures on tax including, if applicable, a link or reference to the external assurance report(s) or assurance statement(s).",
                    state::section2072A3.name,
                    state.section2072A3
                )
            ),
            props = props
        )
    }
}