package com.ecosave.watch.portal.components.esg.waste

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.*
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val WasteComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandardName = EsgSection.WASTE

    reportState.report?.waste?.let { state ->

        fun stateSetter(state: WasteState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        waste = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = griStandardName.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::breakdownOfTotalWasteList.name -> {
                            newState.breakdownOfTotalWasteList.add(BreakdownOfTotalWaste())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfTotalWasteDivertedByCompositionList.name -> {
                            newState.breakdownOfTotalWasteDivertedByCompositionList.add(
                                BreakdownOfWasteDivertedByComposition()
                            )
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfTotalWasteDirectedByCompositionList.name -> {
                            newState.breakdownOfTotalWasteDirectedByCompositionList.add(
                                BreakdownOfWasteDirectedByComposition()
                            )
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }
                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = griStandardName,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )

                val newState = state.copy()

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    when (fieldName) {
                        state::breakdownOfTotalWasteList.name -> {
                            newState.breakdownOfTotalWasteList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfTotalWasteDivertedByCompositionList.name -> {
                            newState.breakdownOfTotalWasteDivertedByCompositionList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        state::breakdownOfTotalWasteDirectedByCompositionList.name -> {
                            newState.breakdownOfTotalWasteDirectedByCompositionList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                if (field == newState::breakdownOfTotalWasteList.name && changeIndex != null) {
                    newState.breakdownOfTotalWasteList[changeIndex].let {
                        when (propertyName) {
                            it::section3063WasteGenerated.name -> it.section3063WasteGenerated = propertyValue
                        }
                    }
                }
                if (field == newState::breakdownOfTotalWasteDivertedByCompositionList.name && changeIndex != null) {
                    newState.breakdownOfTotalWasteDivertedByCompositionList[changeIndex].let {
                        when (propertyName) {
                            it::section3064WasteDiverted.name -> it.section3064WasteDiverted = propertyValue
                        }
                    }
                }
                if (field == newState::breakdownOfTotalWasteDirectedByCompositionList.name && changeIndex != null) {
                    newState.breakdownOfTotalWasteDirectedByCompositionList[changeIndex].let {
                        when (propertyName) {
                            it::section3065WasteDirected.name -> it.section3065WasteDirected = propertyValue
                        }
                    }
                }
                when (propertyName) {
                    newState::section3064Current1.name -> newState.section3064Current1 = propertyValue
                    newState::section3064Current4.name -> newState.section3064Current4 = propertyValue
                    newState::section3064Current5.name -> newState.section3064Current5 = propertyValue
                    newState::section3064Current6.name -> newState.section3064Current6 = propertyValue
                    newState::section3064Current7.name -> newState.section3064Current7 = propertyValue
                    newState::section3064Current8.name -> newState.section3064Current8 = propertyValue
                    newState::section3064Current9.name -> newState.section3064Current9 = propertyValue
                    newState::section3064Current10.name -> newState.section3064Current10 = propertyValue
                    newState::section3064Current11.name -> newState.section3064Current11 = propertyValue
                    newState::section3064Current12.name -> newState.section3064Current12 = propertyValue
                    newState::section3064Current13.name -> newState.section3064Current13 = propertyValue
                    newState::section3064Current14.name -> newState.section3064Current14 = propertyValue
                    newState::section3064Current15.name -> newState.section3064Current15 = propertyValue
                    newState::section3064Current16.name -> newState.section3064Current16 = propertyValue
                    newState::section3064Current17.name -> newState.section3064Current17 = propertyValue
                    newState::section3064Current18.name -> newState.section3064Current18 = propertyValue
                    newState::section3064Current19.name -> newState.section3064Current19 = propertyValue
                    newState::section3064Current20.name -> newState.section3064Current20 = propertyValue
                    newState::section3064Current21.name -> newState.section3064Current21 = propertyValue
                    newState::section3064Current22.name -> newState.section3064Current22 = propertyValue

                    newState::section3064Onsite1.name -> newState.section3064Onsite1 = propertyValue
                    newState::section3064Onsite2.name -> newState.section3064Onsite2 = propertyValue
                    newState::section3064Onsite3.name -> newState.section3064Onsite3 = propertyValue
                    newState::section3064Onsite4.name -> newState.section3064Onsite4 = propertyValue
                    newState::section3064Onsite5.name -> newState.section3064Onsite5 = propertyValue
                    newState::section3064Onsite6.name -> newState.section3064Onsite6 = propertyValue
                    newState::section3064Onsite7.name -> newState.section3064Onsite7 = propertyValue

                    newState::section3064Onsite9.name -> newState.section3064Onsite9 = propertyValue
                    newState::section3064Onsite10.name -> newState.section3064Onsite10 = propertyValue
                    newState::section3064Onsite11.name -> newState.section3064Onsite11 = propertyValue
                    newState::section3064Onsite12.name -> newState.section3064Onsite12 = propertyValue
                    newState::section3064Onsite13.name -> newState.section3064Onsite13 = propertyValue
                    newState::section3064Onsite14.name -> newState.section3064Onsite14 = propertyValue
                    newState::section3064Onsite15.name -> newState.section3064Onsite15 = propertyValue

                    newState::section3064Offsite1.name -> newState.section3064Offsite1 = propertyValue
                    newState::section3064Offsite2.name -> newState.section3064Offsite2 = propertyValue
                    newState::section3064Offsite3.name -> newState.section3064Offsite3 = propertyValue
                    newState::section3064Offsite4.name -> newState.section3064Offsite4 = propertyValue
                    newState::section3064Offsite5.name -> newState.section3064Offsite5 = propertyValue
                    newState::section3064Offsite6.name -> newState.section3064Offsite6 = propertyValue
                    newState::section3064Offsite7.name -> newState.section3064Offsite7 = propertyValue

                    newState::section3064Offsite9.name -> newState.section3064Offsite9 = propertyValue
                    newState::section3064Offsite10.name -> newState.section3064Offsite10 = propertyValue
                    newState::section3064Offsite11.name -> newState.section3064Offsite11 = propertyValue
                    newState::section3064Offsite12.name -> newState.section3064Offsite12 = propertyValue
                    newState::section3064Offsite13.name -> newState.section3064Offsite13 = propertyValue
                    newState::section3064Offsite14.name -> newState.section3064Offsite14 = propertyValue
                    newState::section3064Offsite15.name -> newState.section3064Offsite15 = propertyValue

                    newState::section3064Total17.name -> newState.section3064Total17 = propertyValue

                    newState::section3065Current2.name -> newState.section3065Current2 = propertyValue
                    newState::section3065Current3.name -> newState.section3065Current3 = propertyValue
                    newState::section3065Current7.name -> newState.section3065Current7 = propertyValue
                    newState::section3065Current10.name -> newState.section3065Current10 = propertyValue
                    newState::section3065Current13.name -> newState.section3065Current13 = propertyValue

                    newState::section3065Onsite9.name -> newState.section3065Onsite9 = propertyValue
                    newState::section3065Onsite10.name -> newState.section3065Onsite10 = propertyValue
                    newState::section3065Onsite11.name -> newState.section3065Onsite11 = propertyValue
                    newState::section3065Onsite12.name -> newState.section3065Onsite12 = propertyValue

                    newState::section3065Offsite9.name -> newState.section3065Offsite9 = propertyValue
                    newState::section3065Offsite10.name -> newState.section3065Offsite10 = propertyValue
                    newState::section3065Offsite11.name -> newState.section3065Offsite11 = propertyValue
                    newState::section3065Offsite12.name -> newState.section3065Offsite12 = propertyValue

                    newState::section3065Onsite1.name -> newState.section3065Onsite1 = propertyValue
                    newState::section3065Onsite2.name -> newState.section3065Onsite2 = propertyValue
                    newState::section3065Onsite3.name -> newState.section3065Onsite3 = propertyValue
                    newState::section3065Onsite4.name -> newState.section3065Onsite4 = propertyValue

                    newState::section3065Offsite1.name -> newState.section3065Offsite1 = propertyValue
                    newState::section3065Offsite2.name -> newState.section3065Offsite2 = propertyValue
                    newState::section3065Offsite3.name -> newState.section3065Offsite3 = propertyValue
                    newState::section3065Offsite4.name -> newState.section3065Offsite4 = propertyValue

                    newState::section3065Current17.name -> newState.section3065Current17 = propertyValue
                    newState::section3065Current20.name -> newState.section3065Current20 = propertyValue
                    newState::section3065Current23.name -> newState.section3065Current23 = propertyValue
                    newState::section3065Current16.name -> newState.section3065Current16 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandardName.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandardName.name
                )
            }
        }

        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let { wasteState ->
                if (field == wasteState::breakdownOfTotalWasteList.name && changeIndex != null) {
                    wasteState.breakdownOfTotalWasteList[changeIndex].let {
                        when (propertyName) {
                            it::section3063TypeOfWaste.name -> it.section3063TypeOfWaste = propertyValue
                            it::section3063Material.name -> it.section3063Material = propertyValue
                            it::section3063WasteStream.name -> it.section3063WasteStream = propertyValue
                        }
                    }
                }
                if (field == wasteState::breakdownOfTotalWasteDivertedByCompositionList.name && changeIndex != null) {
                    wasteState.breakdownOfTotalWasteDivertedByCompositionList[changeIndex].let {
                        when (propertyName) {
                            it::section3064TypeOfWaste.name -> it.section3064TypeOfWaste = propertyValue
                            it::section3064WasteStream.name -> it.section3064WasteStream = propertyValue
                            it::section3064Material.name -> it.section3064Material = propertyValue
                        }
                    }
                }
            }


            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3_3_306A1.name -> it.section3_3_306A1 = propertyValue
                    it::section3_3_306A2.name -> it.section3_3_306A2 = propertyValue
                    it::section3_3_306A3.name -> it.section3_3_306A3 = propertyValue
                    it::section3_3_306A4.name -> it.section3_3_306A4 = propertyValue
                    it::section3_3_306A5.name -> it.section3_3_306A5 = propertyValue
                    it::section3_3_306A6.name -> it.section3_3_306A6 = propertyValue

                    it::section3061A3.name -> it.section3061A3 = propertyValue
                    it::section3061A4.name -> it.section3061A4 = propertyValue

                    it::section3062A1.name -> it.section3062A1 = propertyValue
                    it::section3062A2.name -> it.section3062A2 = propertyValue
                    it::section3062A3.name -> it.section3062A3 = propertyValue

                    it::section3063Field1.name -> it.section3063Field1 = propertyValue

                    it::section3064Field2.name -> it.section3064Field2 = propertyValue
                    it::section3064Field3.name -> it.section3064Field3 = propertyValue
                    it::section3064Field4.name -> it.section3064Field4 = propertyValue
                    it::section3064Field5.name -> it.section3064Field5 = propertyValue
                    it::section3064Field6.name -> it.section3064Field6 = propertyValue
                    it::section3064Field7.name -> it.section3064Field7 = propertyValue

                    it::section3065Field1.name -> it.section3065Field1 = propertyValue
                    it::section3065Field2.name -> it.section3065Field2 = propertyValue
                    it::section3065Field3.name -> it.section3065Field3 = propertyValue
                }

            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }
        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            GRIStandardName {
                griStandard = griStandardName
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandardName.description

                this.section3_3_A1_name = state::section3_3_306A1.name
                this.section3_3_A1_value = state.section3_3_306A1
                this.section3_3_A2_name = state::section3_3_306A2.name
                this.section3_3_A2_value = state.section3_3_306A2
                this.section3_3_A3_name = state::section3_3_306A3.name
                this.section3_3_A3_value = state.section3_3_306A3
                this.section3_3_A4_name = state::section3_3_306A4.name
                this.section3_3_A4_value = state.section3_3_306A4
                this.section3_3_A5_name = state::section3_3_306A5.name
                this.section3_3_A5_value = state.section3_3_306A5
                this.section3_3_A6_name = state::section3_3_306A6.name
                this.section3_3_A6_value = state.section3_3_306A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_306_1)) {
                GRI_306_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_306_2)) {
                GRI_306_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_306_3)) {
                GRI_306_3 {
                    this.esgReportState = reportState
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_306_4)) {
                GRI_306_4 {
                    this.esgReportState = reportState
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText

                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_306_5)) {
                GRI_306_5 {
                    this.esgReportState = reportState
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText

                }
            }

        }
    }
}