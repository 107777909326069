package com.ecosave.watch.portal.components.esg.waste

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import react.FC

val GRI_306_1 = FC<EsgSubFormComponentProps>{ props ->

    props.esgReportState.report?.waste?.let { state ->
        DisclosureName{
            disclosure = GriDisclosureTitles.GRI_306_1
        }
        DisclosureSubHeading{
            subHeading = "For the organization’s significant actual and potential waste-related impacts, a description of:"
        }
        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "the inputs, activities, and outputs that lead or could lead to these impacts;",
                    state::section3061A3.name,
                    state.section3061A3
                ),
                TextAreaFieldMapper(
                    "whether these impacts relate to waste generated in the organization’s own activities or " +
                        "to waste generated upstream or downstream in its value chain",
                    state::section3061A4.name,
                    state.section3061A4
                ),
            ),
            props
        )
    }

}