package com.ecosave.watch.portal.components.esg.indirecteconomicimpacts

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px


val Disclosure_203_2 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.indirectEconomicImpacts?.let { state ->
        val indirectEconomicImpactListName = state::indirectEconomicImpactList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_203_2
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            Box {
                className = ESGCommonStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRowOrSection = { props.addRowOrSection(indirectEconomicImpactListName) }
                    items = state.indirectEconomicImpactList
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowOrSectionBtnText = "Add a new section"
                    Box {
                        sx {
                            padding = 20.px
                        }
                        DisclosureSubHeading {
                            subHeading = "Identified Indirect Economic Impact"
                        }
                    }
                }
            }


            state.indirectEconomicImpactList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(indirectEconomicImpactListName, index)
                    }
                    items = state.indirectEconomicImpactList
                    action = DynamicTableAction.DELETE
                    Box {
                        className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                        DisclosureSubHeading {
                            subHeading = "Identified Indirect Economic Impact ${index + 1}"
                        }
                        Box {
                            label {
                                +"Name of Identified Indirect Economic Impact."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2032A1.name
                                value = it.section2032A1
                                onChange = { event -> onChangeTextArea(event, index, indirectEconomicImpactListName) }
                                onBlur = { event -> onBlurTextArea(event, index, indirectEconomicImpactListName) }
                            }
                        }
                        Box {
                            label {
                                +"Positive and Negative Impacts of Identified Indirect Economic Impact."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2032B1.name
                                value = it.section2032B1
                                onChange = { event -> onChangeTextArea(event, index, indirectEconomicImpactListName) }
                                onBlur = { event -> onBlurTextArea(event, index, indirectEconomicImpactListName) }
                            }
                        }
                        Box {
                            label {
                                +"Significance of the indirect economic impact in the context of external benchmarks and stakeholder priorities, such as national and international standards, protocols, and policy agendas."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2032C1.name
                                value = it.section2032C1
                                onChange = { event -> onChangeTextArea(event, index, indirectEconomicImpactListName) }
                                onBlur = { event -> onBlurTextArea(event, index, indirectEconomicImpactListName) }
                            }
                        }
                    }
                }
            }
        }
    }
}
