package com.ecosave.watch.portal.components.esg.materialtopic

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.*
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import react.FC


val Disclosure_3_1 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.disclosuresOnMaterialTopics?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_3_1
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "Describe the process it has followed to determine its material topics, including:",
                    state::section31A1.name,
                    state.section31A1,
                    listHeaders = listOf(
                        "how it has identified actual and potential, negative and positive impacts on the economy, environment, and people, including impacts on their human rights, across its activities and business relationships.",
                        "how it has prioritized the impacts for reporting based on their significance."
                    )
                ),
                TextAreaFieldMapper(
                    "Specify the stakeholders and experts whose views have informed the process of determining its material topics.",
                    state::section31A2.name,
                    state.section31A2
                ),
            ),
            props = props
        )
    }
}
