package com.ecosave.watch.portal.components.esg.materials

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_301_3 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.materials?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_301_3
        }

        Box {
            label {
                +"Percentage of reclaimed products and their packaging materials for each product category."
            }
            ESGTextAreaFieldWrapper {
                name = state::section3013Current1.name
                value = state.section3013Current1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"How the data for this disclosure have been collected?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section3013Field1.name
                value = state.section3013Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}