package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class MarketPresenceState(
    var section2021A1: String? = null,
    var section2021A2: String? = null,
    var section2021A3: String? = null,
    var section2021A4: String? = null,

    var section2022A1: Double? = null,
    var section2022A2: String? = null,
    var section2022A3: String? = null,
    var section2022A4: String? = null,

    var section3_3_202A1: String? = null,
    var section3_3_202A2: String? = null,
    var section3_3_202A3: String? = null,
    var section3_3_202A4: String? = null,
    var section3_3_202A5: String? = null,
    var section3_3_202A6: String? = null,
)