package com.ecosave.watch.portal.models.energymanagement

import com.ecosave.watch.portal.helpers.billing.UtilityType

sealed external interface UtilityData {
    var utilityType: String
    var billingMonth: String
    var utilityUsage: Double
    var utilityCost: Double
    var utilityCo2Emission: Double
    var billingMonthTwo: String?
    var utilityCostTwo: Double?
    var utilityUsageTwo: Double?
    var utilityCo2EmissionTwo: Double?
    var billingMonthThree: String?
    var utilityCostThree: Double?
    var utilityUsageThree: Double?
    var utilityCo2EmissionThree: Double?
    var dataKey: String
}

sealed external interface TotalUtilityData {
    var utilityType: String
    var utilityUsage: Double
    var utilityUsageInMMBtu: Double
    var utilityCost: Double
    var utilityCo2Emission: Double
}

enum class EventType(val label: String) {
    UN_ASSIGNED("Unassigned"),
    ASSIGNED("Assigned"),
    IN_PROGRESS("In Progress"),
    COMPLETED("Closed")
}

sealed external interface Event {
    var name: String
    var value: Int
}

data class SummaryData(
    var sum: Double = 0.0,
    var highestMonth: String = "",
    var highestAmount: Double = 0.0,
    var lastMonth: String = "",
    var lastAmount: Double = 0.0,
)

data class MonthAndValue(
    var month: String,
    var value: Double,
)

data class BrushPoint(
    var index: Int = 0,
    var value: String = "",
)

data class RankingUnit(
    var name: String,
    var unit: String,
)

data class BaselineTargetDataPerUtility(
    var utilityType: UtilityType,
    var baselineTargetData: Array<UtilityData>,
)

enum class BaselineSelectionStatus(val description: String) {
    NOT_AVAILABLE("No baseline is available"),
    SELECTED("Baseline is selected"),
    NOT_SELECTED("Baseline is not selected")
}

sealed external interface XAxisPayload {
    var value: String
    var coordinate: Double
    var index: Int
    var offset: Double
}

sealed external interface RankingData {
    var facilityName: String
    var facilityId: Int
    var facilityDescription: String?
    var co2Intensity: Double
    var siteEnergyUseIntensity: Double
    var costIntensity: Double
}