package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class TaxState(
    var section2071A1: String? = null,

    var section2072A1: String? = null,
    var section2072A2: String? = null,
    var section2072A3: String? = null,

    var section2073A1: String? = null,

    var section2074A1: String? = null,
    var section2074A2: String? = null,

    var taxJurisdictionList: MutableList<TaxJurisdictions> = mutableListOf(),

    var section3_3_207A1: String? = null,
    var section3_3_207A2: String? = null,
    var section3_3_207A3: String? = null,
    var section3_3_207A4: String? = null,
    var section3_3_207A5: String? = null,
    var section3_3_207A6: String? = null
)

@Serializable
data class TaxJurisdictions(
    var section2074Part2A1: String? = null,
    var section2074Part2A2: String? = null,
    var section2074Part2A3: String? = null,
    var section2074Part2A4: String? = null,
    var section2074Part2A5: String? = null,
    var section2074Part2A6: String? = null,
    var section2074Part2A7: String? = null,
    var section2074Part2A8: String? = null,
    var section2074Part2A9: String? = null,
    var section2074Part2A10: String? = null,
    var section2074Part2A11: String? = null,
) : DynamicTableItemKey()
