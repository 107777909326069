package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class DisclosuresOnMaterialTopicsState(
    var section31A1: String? = null,
    var section31A2: String? = null,

    var selectedMaterialTopics: MutableList<MaterialTopicManagement> = mutableListOf(),

    var section31A3: String? = null

)

@Serializable
data class MaterialTopicManagement(
    var materialTopicName: String? = null
) : DynamicTableItemKey()
