package com.ecosave.watch.portal.components.esg.materials

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGBaseFormBuilder
import com.ecosave.watch.portal.components.esg.formcontrols.TextAreaFieldMapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import react.FC

val GRI_301_1 = FC<EsgSubFormComponentProps> { props ->

    props.esgReportState.report?.materials?.let { state ->
        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_301_1
        }

        DisclosureSubHeading {
            subHeading =
                "Total weight or volume of materials that are used to produce and package the organization’s primary products and services during the reporting period, by:"
        }

        ESGBaseFormBuilder(
            listOf(
                TextAreaFieldMapper(
                    "non-renewable materials used;",
                    state::section3011A1.name,
                    state.section3011A1
                ),
                TextAreaFieldMapper(
                    "renewable materials used.",
                    state::section3011A2.name,
                    state.section3011A2
                )
            ),
            props = props
        )
    }
}