package com.ecosave.watch.portal.components.esg.emissions

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.*
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.*
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val EmissionsComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandardName = EsgSection.EMISSIONS

    reportState.report?.emissions?.let { state ->

        fun stateSetter(state: EmissionsState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        emissions = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = griStandardName.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()
                    when (fieldName) {
                        state::breakdownOfGHGEmissionOnScopeTypeList.name -> {
                            newState.breakdownOfGHGEmissionOnScopeTypeList.add(BreakdownGHGEmissionOnScopeType())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                        state::breakdownOfOtherEmissionTypeList.name -> {
                            newState.breakdownOfOtherEmissionTypeList.add(OtherEmissionType())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }
                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = griStandardName,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )

                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()
                    when (fieldName) {
                        state::breakdownOfGHGEmissionOnScopeTypeList.name -> {
                            newState.breakdownOfGHGEmissionOnScopeTypeList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                        state::breakdownOfOtherEmissionTypeList.name -> {
                            newState.breakdownOfOtherEmissionTypeList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }
                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }


        val onChangeText: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3054Unit1.name -> it.section3054Unit1 = propertyValue
                    it::section3054Unit2.name -> it.section3054Unit2 = propertyValue
                    it::section3054Unit3.name -> it.section3054Unit3 = propertyValue
                }

                if (field == it::breakdownOfGHGEmissionOnScopeTypeList.name && changeIndex != null) {
                    newState.breakdownOfGHGEmissionOnScopeTypeList[changeIndex].let { breakedown ->
                        when (propertyName) {
                             breakedown::section3055ScopeType.name -> breakedown.section3055ScopeType = propertyValue
                        }
                    }
                }
                if (field == it::breakdownOfOtherEmissionTypeList.name && changeIndex != null) {
                    newState.breakdownOfOtherEmissionTypeList[changeIndex].let { breakedown ->
                        when (propertyName) {
                            breakedown::section3057A1.name -> breakedown.section3057A1 = propertyValue
                            breakedown::section3057B1.name -> breakedown.section3057B1 = propertyValue
                        }
                    }
                }
            }


            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue.trim()),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurText: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            newState.let {
                when (propertyName) {
                    it::section3_3_305A1.name -> it.section3_3_305A1 = propertyValue
                    it::section3_3_305A2.name -> it.section3_3_305A2 = propertyValue
                    it::section3_3_305A3.name -> it.section3_3_305A3 = propertyValue
                    it::section3_3_305A4.name -> it.section3_3_305A4 = propertyValue
                    it::section3_3_305A5.name -> it.section3_3_305A5 = propertyValue
                    it::section3_3_305A6.name -> it.section3_3_305A6 = propertyValue

                    it::section3051Current2.name -> it.section3051Current2 = propertyValue

                    it::section3051Field1.name -> it.section3051Field1 = propertyValue
                    it::section3051Field2.name -> it.section3051Field2 = propertyValue
                    it::section3051Field3.name -> it.section3051Field3 = propertyValue
                    it::section3051Field4.name -> it.section3051Field4 = propertyValue

                    it::section3052Field1.name -> it.section3052Field1 = propertyValue
                    it::section3052Field2.name -> it.section3052Field2 = propertyValue
                    it::section3052Field3.name -> it.section3052Field3 = propertyValue
                    it::section3053Field4.name -> it.section3053Field4 = propertyValue

                    it::section3053Current2.name -> it.section3053Current2 = propertyValue

                    it::section3053Field1.name -> it.section3053Field1 = propertyValue
                    it::section3053Field2.name -> it.section3053Field2 = propertyValue
                    it::section3053Field3.name -> it.section3053Field3 = propertyValue
                    it::section3053Field4.name -> it.section3053Field4 = propertyValue

                    it::section3054Field1.name -> it.section3054Field1 = propertyValue
                    it::section3054Field2.name -> it.section3054Field2 = propertyValue

                    it::section3056Field3.name -> it.section3056Field3 = propertyValue
                    it::section3056Field4.name -> it.section3056Field4 = propertyValue
                    it::section3056Field5.name -> it.section3056Field5 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandardName.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandardName.name
            )
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement

            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                newState.let {
                    when (propertyName) {
                        it::section3051Current1.name -> it.section3051Current1 = propertyValue
                        it::section3051Current3.name -> it.section3051Current3 = propertyValue

                        it::section3052Current1.name -> it.section3052Current1 = propertyValue
                        it::section3052Current2.name -> it.section3052Current2 = propertyValue

                        it::section3053Current1.name -> it.section3053Current1 = propertyValue
                        it::section3053Current3.name -> it.section3053Current3 = propertyValue

                        it::section3054Current1.name -> it.section3054Current1 = propertyValue
                        it::section3054Current2.name -> it.section3054Current2 = propertyValue
                        it::section3054Current3.name -> it.section3054Current3 = propertyValue

                        it::section3055Current1.name -> it.section3055Current1 = propertyValue

                        it::section3056Current1.name -> it.section3056Current1 = propertyValue
                        it::section3056Current2.name -> it.section3056Current2 = propertyValue
                        it::section3056Current3.name -> it.section3056Current3 = propertyValue
                        it::section3056Current4.name -> it.section3056Current4 = propertyValue
                        it::section3056Field1.name -> it.section3056Field1 = propertyValue
                        it::section3056Field2.name -> it.section3056Field2 = propertyValue

                    }

                    if (field == it::breakdownOfGHGEmissionOnScopeTypeList.name && changeIndex != null) {
                        newState.breakdownOfGHGEmissionOnScopeTypeList[changeIndex].let { breakedown ->
                            when (propertyName) {
                                breakedown::section3055ReducedEmission.name -> breakedown.section3055ReducedEmission = propertyValue
                            }
                        }
                    }

                    if (field == it::breakdownOfOtherEmissionTypeList.name && changeIndex != null) {
                        newState.breakdownOfOtherEmissionTypeList[changeIndex].let { breakedown ->
                            when (propertyName) {
                                breakedown::section3057C1.name -> breakedown.section3057C1 = propertyValue
                            }
                        }
                    }
                }
                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandardName.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandardName.name
                )
            }
        }


        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            GRIStandardName {
                griStandard = griStandardName
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandardName.description

                this.section3_3_A1_name = state::section3_3_305A1.name
                this.section3_3_A1_value = state.section3_3_305A1
                this.section3_3_A2_name = state::section3_3_305A2.name
                this.section3_3_A2_value = state.section3_3_305A2
                this.section3_3_A3_name = state::section3_3_305A3.name
                this.section3_3_A3_value = state.section3_3_305A3
                this.section3_3_A4_name = state::section3_3_305A4.name
                this.section3_3_A4_value = state.section3_3_305A4
                this.section3_3_A5_name = state::section3_3_305A5.name
                this.section3_3_A5_value = state.section3_3_305A5
                this.section3_3_A6_name = state::section3_3_305A6.name
                this.section3_3_A6_value = state.section3_3_305A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_1)) {
                GRI_305_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_2)) {
                GRI_305_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_3)) {
                GRI_305_3 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_4)) {
                GRI_305_4 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_5)) {
                GRI_305_5 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_6)) {
                GRI_305_6 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_305_7)) {
                GRI_305_7 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                    this.onChangeText = onChangeText
                    this.onBlurText = onBlurText
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }

        }
    }
}