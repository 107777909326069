package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class InfrastructureInvestments(
    var section2031Part1A1: String? = null,
    var section2031Part1A2: String? = null,
    var section2031Part1A3: String? = null,
    var section2031Part1A4: String? = null,
) : DynamicTableItemKey()


@Serializable
data class IndirectEconomicImpactTable(
    var section2032A1: String? = null,
    var section2032B1: String? = null,
    var section2032C1: String? = null
) : DynamicTableItemKey()

@Serializable
data class IndirectEconomicImpactsState(
    var infrastureInvestmentList: MutableList<InfrastructureInvestments> = mutableListOf(),
    var indirectEconomicImpactList: MutableList<IndirectEconomicImpactTable> = mutableListOf(),

    var section3_3_203A1: String? = null,
    var section3_3_203A2: String? = null,
    var section3_3_203A3: String? = null,
    var section3_3_203A4: String? = null,
    var section3_3_203A5: String? = null,
    var section3_3_203A6: String? = null
)