package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class WasteState(
    var breakdownOfTotalWasteList: MutableList<BreakdownOfTotalWaste> = mutableListOf(BreakdownOfTotalWaste()),
    var section3063TotalWasteList: MutableList<TotalWasteGenerated>? = mutableListOf(),
    var breakdownOfTotalWasteDivertedByCompositionList: MutableList<BreakdownOfWasteDivertedByComposition> = mutableListOf(
        BreakdownOfWasteDivertedByComposition()
    ),
    var section3064TotalWasteDiverted: Double? = null,
    var section3064TotalWasteDirected: Double? = null,
    var section3063TotalWasteGenerated1: Double? = null,

    var breakdownOfTotalWasteDirectedByCompositionList: MutableList<BreakdownOfWasteDirectedByComposition> = mutableListOf(BreakdownOfWasteDirectedByComposition()),

    var section3061A3: String? = null,
    var section3061A4: String? = null,

    var section3062A1: String? = null,
    var section3062A2: String? = null,
    var section3062A3: String? = null,

    var section3063Current1: Double? = null,
    var section3063Current2: Double? = null,
    var section3063Current3: Double? = null,
    val section3063TotalWaste1: Double? = null,
    var section3063Current4: Double? = null,
    var section3063Current5: Double? = null,
    var section3063Current6: Double? = null,
    var section3063TotalWaste2: Double? = null,
    var section3063TotalWastePercentage1: Double? = null,
    var section3063TotalWastePercentage2: Double? = null,
    var section3063Field1: String? = null,
    var section3063TotalWasteGenerated: Double? = null,
    var section3063TotalWasteDiverted: Double? = null,
    var section3063TotalWasteDirected: Double? = null,

    var section3064Current1: Double? = null,
    var section3064Current2: Double? = null,
    var section3064Current3: Double? = null,
    var section3064Current4: Double? = null,
    var section3064Current5: Double? = null,
    var section3064Current6: Double? = null,
    var section3064Current7: Double? = null,
    var section3064Current8: Double? = null,
    var section3064Current9: Double? = null,
    var section3064Current10: Double? = null,
    var section3064Current11: Double? = null,
    var section3064Current12: Double? = null,
    var section3064Current13: Double? = null,
    var section3064Current14: Double? = null,
    var section3064Current15: Double? = null,
    var section3064Current16: Double? = null,
    var section3064Current17: Double? = null,
    var section3064Current18: Double? = null,
    var section3064Current19: Double? = null,
    var section3064Current20: Double? = null,
    var section3064Current21: Double? = null,
    var section3064Current22: Double? = null,
    var section3064Current23: Double? = null,

    var section3064Field1: String? = null,
    var section3064Field2: String? = null,
    var section3064Field3: String? = null,
    var section3064Field4: String? = null,
    var section3064Field5: String? = null,
    var section3064Field6: String? = null,
    var section3064Field7: String? = null,


    var section3064Onsite1: Double? = null,
    var section3064Onsite2: Double? = null,
    var section3064Onsite4: Double? = null,
    var section3064Onsite3: Double? = null,
    var section3064Onsite5: Double? = null,
    var section3064Onsite6: Double? = null,
    var section3064Onsite7: Double? = null,
    var section3064TotalOnsite1: Double? = null,
    var section3064Offsite1: Double? = null,
    var section3064Offsite2: Double? = null,
    var section3064Offsite3: Double? = null,
    var section3064Offsite4: Double? = null,
    var section3064Offsite5: Double? = null,
    var section3064Offsite6: Double? = null,
    var section3064Offsite7: Double? = null,
    var section3064TotalOffsite1: Double? = null,
    var section3064Total1: Double? = null,
    var section3064Total2: Double? = null,
    var section3064Total3: Double? = null,
    var section3064Total4: Double? = null,
    var section3064Total5: Double? = null,
    var section3064Total6: Double? = null,
    var section3064Total7: Double? = null,
    var section3064Total8: Double? = null,

    var section3064Onsite9: Double? = null,
    var section3064Onsite10: Double? = null,
    var section3064Onsite11: Double? = null,
    var section3064Onsite12: Double? = null,
    var section3064Onsite13: Double? = null,
    var section3064Onsite14: Double? = null,
    var section3064Onsite15: Double? = null,
    var section3064TotalOnsite2: Double? = null,
    var section3064Offsite9: Double? = null,
    var section3064Offsite10: Double? = null,
    var section3064Offsite11: Double? = null,
    var section3064Offsite12: Double? = null,
    var section3064Offsite13: Double? = null,
    var section3064Offsite14: Double? = null,
    var section3064Offsite15: Double? = null,
    var section3064TotalOffsite2: Double? = null,
    var section3064Total9: Double? = null,
    var section3064Total10: Double? = null,
    var section3064Total11: Double? = null,
    var section3064Total12: Double? = null,
    var section3064Total13: Double? = null,
    var section3064Total14: Double? = null,
    var section3064Total15: Double? = null,
    var section3064Total16: Double? = null,
    var section3064Total17: Double? = null,

    var section3065Current1: Double? = null,
    var section3065Current2: Double? = null,
    var section3065Current3: Double? = null,
    var section3065Current4: Double? = null,
    var section3065Current5: Double? = null,
    var section3065Current6: Double? = null,
    var section3065Current7: Double? = null,
    var section3065Current8: Double? = null,
    var section3065Current9: Double? = null,
    var section3065Current10: Double? = null,
    var section3065Current11: Double? = null,
    var section3065Current12: Double? = null,
    var section3065Current13: Double? = null,
    var section3065Current14: Double? = null,
    var section3065Current15: Double? = null,
    var section3065Current16: Double? = null,
    var section3065Current17: Double? = null,
    var section3065Current18: Double? = null,
    var section3065Current19: Double? = null,
    var section3065Current20: Double? = null,
    var section3065Current21: Double? = null,
    var section3065Current22: Double? = null,
    var section3065Current23: Double? = null,
    var section3065Current24: Double? = null,
    var section3065Current25: Double? = null,
    var section3065Current26: Double? = null,
    var section3065Current27: Double? = null,
    var section3065Current28: Double? = null,

    var section3065Onsite1: Double? = null,
    var section3065Onsite2: Double? = null,
    var section3065Onsite4: Double? = null,
    var section3065Onsite3: Double? = null,
    var section3065Onsite5: Double? = null,
    var section3065Onsite6: Double? = null,
    var section3065Onsite7: Double? = null,
    var section3065TotalOnsite1: Double? = null,
    var section3065Offsite1: Double? = null,
    var section3065Offsite2: Double? = null,
    var section3065Offsite3: Double? = null,
    var section3065Offsite4: Double? = null,
    var section3065Offsite5: Double? = null,
    var section3065Offsite6: Double? = null,
    var section3065Offsite7: Double? = null,
    var section3065TotalOffsite1: Double? = null,
    var section3065Total1: Double? = null,
    var section3065Total2: Double? = null,
    var section3065Total3: Double? = null,
    var section3065Total4: Double? = null,
    var section3065Total5: Double? = null,
    var section3065Total6: Double? = null,
    var section3065Total7: Double? = null,
    var section3065Total8: Double? = null,

    var section3065Onsite9: Double? = null,
    var section3065Onsite10: Double? = null,
    var section3065Onsite11: Double? = null,
    var section3065Onsite12: Double? = null,
    var section3065Onsite13: Double? = null,
    var section3065Onsite14: Double? = null,
    var section3065Onsite15: Double? = null,
    var section3065TotalOnsite2: Double? = null,
    var section3065Offsite9: Double? = null,
    var section3065Offsite10: Double? = null,
    var section3065Offsite11: Double? = null,
    var section3065Offsite12: Double? = null,
    var section3065Offsite13: Double? = null,
    var section3065Offsite14: Double? = null,
    var section3065Offsite15: Double? = null,
    var section3065TotalOffsite2: Double? = null,
    var section3065Total9: Double? = null,
    var section3065Total10: Double? = null,
    var section3065Total11: Double? = null,
    var section3065Total12: Double? = null,
    var section3065Total13: Double? = null,
    var section3065Total14: Double? = null,
    var section3065Total15: Double? = null,
    var section3065Total16: Double? = null,

    var section3065Field1: String? = null,
    var section3065Field2: String? = null,
    var section3065Field3: String? = null,

    var section3_3_306A1: String? = null,
    var section3_3_306A2: String? = null,
    var section3_3_306A3: String? = null,
    var section3_3_306A4: String? = null,
    var section3_3_306A5: String? = null,
    var section3_3_306A6: String? = null
)

@Serializable
data class BreakdownOfTotalWaste(
    var section3063TypeOfWaste: String? = null,
    var section3063WasteStream: String? = null,
    var section3063Material: String? = null,
    var section3063WasteGenerated: Double? = null,
    var section3063WasteDiverted: Double? = null,
    var section3063BWasteDirected: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class TotalWasteGenerated(
    var section3063TypeOfWaste: String? = null,
    var section3063QuantityOfWaste: Double? = null,
    var section3063TotalWaste: Double? = null,
    var section3063WasteGeneratedPer: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class BreakdownOfWasteDivertedByComposition(
    var section3064TypeOfWaste: String? = null,
    var section3064WasteStream: String? = null,
    var section3064Material: String? = null,
    var section3064WasteDiverted: Double? = null,
) : DynamicTableItemKey()

@Serializable
data class BreakdownOfWasteDirectedByComposition(
    var section3065TypeOfWaste: String? = null,
    var section3065WasteStream: String? = null,
    var section3065Material: String? = null,
    var section3065WasteDirected: Double? = null,
) : DynamicTableItemKey()

