package com.ecosave.watch.portal.components.esg.marketpresence

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.MarketPresenceState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val MarketPresenceComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandard = EsgSection.MARKET_PRESENCE

    reportState.report?.marketPresence?.let { state ->

        fun stateSetter(state: MarketPresenceState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        marketPresence = state
                    )
                )
            )
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            when (propertyName) {
                state::section2021A1.name -> newState.section2021A1 = propertyValue
                state::section2021A2.name -> newState.section2021A2 = propertyValue
                state::section2021A3.name -> newState.section2021A3 = propertyValue
                state::section2021A4.name -> newState.section2021A4 = propertyValue

                state::section2022A2.name -> newState.section2022A2 = propertyValue
                state::section2022A3.name -> newState.section2022A3 = propertyValue
                state::section2022A4.name -> newState.section2022A4 = propertyValue

                state::section3_3_202A1.name -> newState.section3_3_202A1 = propertyValue
                state::section3_3_202A2.name -> newState.section3_3_202A2 = propertyValue
                state::section3_3_202A3.name -> newState.section3_3_202A3 = propertyValue
                state::section3_3_202A4.name -> newState.section3_3_202A4 = propertyValue
                state::section3_3_202A5.name -> newState.section3_3_202A5 = propertyValue
                state::section3_3_202A6.name -> newState.section3_3_202A6 = propertyValue
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandard.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandard.name
            )
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, listName ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section2022A1.name -> newState.section2022A1 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = listName,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = griStandard.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    griStandard.name
                )
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                this.griStandard = griStandard
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandard.description
                this.section3_3_A1_name = state::section3_3_202A1.name
                this.section3_3_A1_value = state.section3_3_202A1

                this.section3_3_A2_value = state.section3_3_202A2
                this.section3_3_A2_name = state::section3_3_202A2.name

                this.section3_3_A3_name = state::section3_3_202A3.name
                this.section3_3_A3_value = state.section3_3_202A3

                this.section3_3_A4_name = state::section3_3_202A4.name
                this.section3_3_A4_value = state.section3_3_202A4

                this.section3_3_A5_name = state::section3_3_202A5.name
                this.section3_3_A5_value = state.section3_3_202A5

                this.section3_3_A6_name = state::section3_3_202A6.name
                this.section3_3_A6_value = state.section3_3_202A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_202_1)) {
                Disclosure_202_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_202_2)) {
                Disclosure_202_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
        }

    }
}

