package com.ecosave.watch.portal.services.esg

import com.ecosave.watch.portal.helpers.BackendReportingServiceURL
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.getAccessTokenFromLocalStorage
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.helpers.esg.transformByteArrayAndDownloadReportPdf
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.EsgCollectionPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgReportCoverPage
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.IncludeOrExcludeGriStandardsRequest
import com.ecosave.watch.portal.models.esg.OmitOrIncludeDisclosureRequest
import com.ecosave.watch.portal.models.esg.OmitOrIncludeDisclosureResponse
import com.ecosave.watch.portal.models.esg.OmittedDetail
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import js.core.jso
import js.promise.await
import kotlin.js.json
import kotlinx.browser.window
import kotlinx.coroutines.await
import org.w3c.fetch.RequestInit
import web.buffer.Blob
import web.buffer.BlobPropertyBag
import web.file.File
import web.http.FormData


suspend fun createEsgReport(
    esgReportState: EsgReportState,
): HttpStatusCode? {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report") {
            setBody(esgReportState)
        }
        return when (response.status) {
            HttpStatusCode.Created -> HttpStatusCode.Created
            HttpStatusCode.Conflict -> HttpStatusCode.Conflict
            else -> null
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun downloadReport(reportFileName: String): ApiCallStatus {
    return try {
        val reportByteArray = getReportByteArray(reportFileName)
        if (reportByteArray != null) {
            transformByteArrayAndDownloadReportPdf(reportByteArray)
            ApiCallStatus.SUCCESS
        } else {
            ApiCallStatus.FAILURE
        }
    } catch (e: Exception) {
        console.error(e.message)
        ApiCallStatus.FAILURE
    }
}

suspend fun getReportByteArray(reportFileName: String): ByteArray? {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendReportingServiceURL/esg-report/download-report?reportFileName=$reportFileName")
        if (response.status == HttpStatusCode.OK) {
            return response.readBytes()
        }
    } catch (e: Exception) {
        console.log("Error: ${e.message}")
    }
    return null
}

suspend fun previewGriStandardReport(reportFileName: String, griStandard: TOCSectionsEnum): ApiCallStatus {
    return try {
        val byteArray = getGriStandardByteArray(reportFileName, griStandard)
        if (byteArray != null) {
            transformByteArrayAndDownloadReportPdf(byteArray)
            ApiCallStatus.SUCCESS
        } else {
            ApiCallStatus.FAILURE
        }
    } catch (e: Exception) {
        console.error(e.message)
        ApiCallStatus.FAILURE
    }
}

suspend fun getGriStandardByteArray(reportFileName: String, griStandard: TOCSectionsEnum): ByteArray? {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendReportingServiceURL/esg-report/preview-report?reportFileName=$reportFileName&section=${griStandard}")
        if (response.status == HttpStatusCode.OK) {
            return response.readBytes()
        }
    } catch (e: Exception) {
        console.log("Error: ${e.message}")
    }
    return null
}

suspend fun getAllReports(): List<EsgReportState>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendReportingServiceURL/esg-report/all")

        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun deleteReport(reportFileName: String): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/?reportFileName=$reportFileName")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun includeOrExcludeGriStandards(reportFileName: String, selectedSectionsList: MutableList<EsgSection>): EsgReportState? {

    try {
        val response: HttpResponse = httpClient.put("$BackendReportingServiceURL/esg-report/update-esg-sections") {
            setBody(
                IncludeOrExcludeGriStandardsRequest(
                    reportFileName,
                    selectedSectionsList
                )
            )
        }
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun addDynamicRowOrSection(patch: EsgCollectionAddRow): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report/collection-item") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.Created) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun deleteDynamicRowOrSection(patch: EsgCollectionDeleteRow): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/collection-item?objectPatchEnum=${patch.objectPatchEnum}&reportFileName=${patch.reportFileName}&pathFirst=${patch.pathFirst}&indexAt=${patch.indexAt}")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

/* objectPatchEnum is of type EsgSection in EsgCollectionDeleteRow and EsgSection do not contain General Disclosures subsections,
 below call is overload of above call to avoid refactor */

suspend fun deleteDynamicRowOrSection(objectPatchEnum: String, reportFileName: String, pathFirst: String, indexAt: Int): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/collection-item?objectPatchEnum=${objectPatchEnum}&reportFileName=${reportFileName}&pathFirst=${pathFirst}&indexAt=${indexAt}")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun autoSaveEsg(patch: EsgPatchUpdate): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun autoSaveEsgCollection(patch: EsgCollectionPatchUpdate): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report/collection-item") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun omitOrIncludeDisclosure(
    omittedDetail: OmittedDetail?,
    reportFileName: String,
    disclosure: GriDisclosureTitles,
): OmitOrIncludeDisclosureResponse? {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report/omission-reason") {
            setBody(
                OmitOrIncludeDisclosureRequest(
                    reportFileName = reportFileName,
                    disclosure = disclosure,
                    omittedDetail = omittedDetail
                )
            )
        }
        if (response.status == HttpStatusCode.Created) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun uploadReportCoverImage(file: File): ApiCallStatus {
    try {
        val accessToken = getAccessTokenFromLocalStorage()
        val formData = FormData()
        val buffer = file.arrayBuffer().await()
        val options: BlobPropertyBag = jso {
            type = file.type
        }
        val blob = Blob(arrayOf(buffer), options)
        formData.append("cover-image", blob, file.name);

        val response = window.fetch(
            "$BackendReportingServiceURL/esg-report/cover-image",
            RequestInit(
                "POST",
                headers = json(
                    "authorization" to "Bearer $accessToken",
                ),
                body = formData
            )
        ).await()

        if (response.status.toInt() == HttpStatusCode.OK.value) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log(e)
    }
    return ApiCallStatus.FAILURE
}

suspend fun getReportCoverImageUrl(): String? {
    try {
        val response: HttpResponse = httpClient.get("$BackendReportingServiceURL/esg-report/cover-image")

        if (response.status == HttpStatusCode.OK) {
            val reportCoverPage: EsgReportCoverPage = response.body()
            return reportCoverPage.esgReportCoverImageUrl
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}