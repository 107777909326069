package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.currentYear
import com.ecosave.watch.portal.helpers.esg.EsgPages
import com.ecosave.watch.portal.helpers.esg.isEsgFormValid
import com.ecosave.watch.portal.helpers.esg.validateCompanyName
import com.ecosave.watch.portal.helpers.esg.validateFinancialYear
import com.ecosave.watch.portal.helpers.esg.validateReportName
import com.ecosave.watch.portal.helpers.esg.validateReportTitle
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.EsgReportValidationState
import com.ecosave.watch.portal.models.esg.ReportFinancialYearValidationState
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.esg.createEsgReport
import com.ecosave.watch.portal.useGlobalState
import io.ktor.http.*
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.FormHelperText
import mui.material.MenuItem
import mui.material.Select
import mui.material.TextField
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML.label
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Float
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType
import com.ecosave.watch.portal.helpers.esg.CreateReportFields as fields
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface CreateReportComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var setVisiblePage: StateSetter<EsgPages>
}

val CreateReportComponent = FC<CreateReportComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val globalState = useGlobalState()
    val (reportValidationState, setReportValidationState) = useState(EsgReportValidationState(reportFinancialYear = ReportFinancialYearValidationState()))
    var creatingReport by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    useEffectOnce {
        globalState.updatePageTitle("Create Report")
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            gap = 20.px
        }
        Box {
            label {
                className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                +fields.FILE_NAME.label
            }
            TextField {
                className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                type = InputType.text
                variant = FormControlVariant.outlined
                value = reportState.reportFileName
                onChange = {
                    val target = it.target as HTMLInputElement
                    setReportState(
                        reportState.copy(
                            reportFileName = target.value
                        )
                    )
                }
                error = reportValidationState.reportFileNameErrorState
                helperText = reportValidationState.reportFileNameErrorMessage
                onBlur = {
                    validateReportName(
                        reportState,
                        reportValidationState,
                        setReportValidationState
                    )
                }
            }
        }
        Box {
            label {
                className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                +fields.REPORT_TITLE.label
            }
            TextField {
                className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                type = InputType.text
                variant = FormControlVariant.outlined
                value = reportState.reportTitle

                onChange = {
                    val target = it.target as HTMLInputElement
                    val updatedReportTitle = target.value
                    setReportState(
                        reportState.copy(
                            reportTitle = updatedReportTitle
                        )
                    )
                }

                error = reportValidationState.reportTitleErrorState
                helperText = reportValidationState.reportTitleErrorMessage
                onBlur = {
                    validateReportTitle(
                        reportState,
                        reportValidationState,
                        setReportValidationState
                    )
                }
            }
        }
        Box {
            label {
                className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                +fields.COMPANY_NAME.label
            }
            TextField {
                className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                type = InputType.text
                variant = FormControlVariant.outlined
                value = reportState.companyName
                onChange = {
                    val target = it.target as HTMLInputElement
                    setReportState(
                        reportState.copy(
                            companyName = target.value
                        )
                    )
                }
                error = reportValidationState.companyNameErrorState
                helperText = reportValidationState.companyNameErrorMessage
                onBlur = {
                    validateCompanyName(
                        reportState,
                        reportValidationState,
                        setReportValidationState
                    )
                }
            }
        }
        Box {
            label {
                className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                +fields.REPORT_FINANCIAL_INFO.label
            }
        }
        Box {
            className = styles.ESG_CALENDAR_MAIN.cssClass
            Box {
                label {
                    className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                    +fields.REPORT_FINANCIAL_YEAR.label
                }
                Box {
                    className = styles.ESG_CALENDAR_TEXT_FONT.cssClass
                    FormControl {
                        fullWidth = true
                        Select {
                            fullWidth = true
                            value = reportState.reportFinancialYear.financialYear
                            for (n in 2000..currentYear) {
                                MenuItem {
                                    value = n.toString()
                                    +n.toString()
                                }
                                onChange = { it, _ ->
                                    val selectedYear = it.target.value.toIntOrNull()
                                    if (selectedYear != null) {
                                        val startDate = "${selectedYear}-01-01"
                                        val endDate = "${selectedYear}-12-31"

                                        setReportState(
                                            reportState.copy(
                                                reportFinancialYear = reportState.reportFinancialYear.copy(
                                                    financialYear = selectedYear,
                                                    calendarDateStart = startDate,
                                                    calendarDateEnd = endDate
                                                )
                                            )
                                        )
                                    }
                                }
                                onBlur = {
                                    validateFinancialYear(
                                        reportState,
                                        reportValidationState,
                                        setReportValidationState
                                    )
                                }
                            }
                        }
                        error = reportValidationState.reportFinancialYear.financialYearErrorState
                        if (reportValidationState.reportFinancialYear.financialYearErrorState) {
                            FormHelperText {
                                +reportValidationState.reportFinancialYear.financialYearErrorMessage
                            }
                        }
                    }
                }
            }
            Box {
                label {
                    className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                    +fields.REPORT_CAL_DATE_START.label
                }
                CalendarDatePicker {
                    esgReportStateProps = reportState
                    esgReportStateSetterProps = setReportState
                    esgReportValidationStateProps = reportValidationState
                    esgReportValidationStateSetterProps = setReportValidationState
                    dateStart = true
                    id = "start-date"
                }
            }
            Box {
                label {
                    className = styles.ESG_CALENDAR_MAIN_FONT.cssClass
                    +fields.REPORT_CAL_DATE_END.label
                }
                CalendarDatePicker {
                    esgReportStateProps = reportState
                    esgReportStateSetterProps = setReportState
                    esgReportValidationStateProps = reportValidationState
                    esgReportValidationStateSetterProps = setReportValidationState
                    dateStart = false
                    id = "end-date"
                }
            }
        }
        Box {
            Button {
                sx {
                    float = Float.right
                }
                variant = ButtonVariant.contained
                disabled = creatingReport
                onClick = {
                    if (isEsgFormValid(reportState, reportValidationState, setReportValidationState)) {
                        mainScope.launch {
                            creatingReport = true
                            val response = createEsgReport(reportState)
                            when (response) {
                                null -> {
                                    setNotificationState(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                            visible = true
                                        )
                                    )
                                }

                                HttpStatusCode.Conflict -> {
                                    setNotificationState(
                                        notificationState.copy(
                                            status = NotificationStatus.ERROR,
                                            message = "Report file name already exists.",
                                            visible = true
                                        )
                                    )
                                }

                                else -> {
                                    props.setVisiblePage(EsgPages.SELECT_GRI_STANDARDS)
                                }
                            }
                            creatingReport = false
                        }
                    }
                }
                if (creatingReport) +"Creating report..." else +"Save and Next"
            }
        }
    }

    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}

