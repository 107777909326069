package com.ecosave.watch.portal.components.esg.indirecteconomicimpacts

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.components.esg.formcontrols.GRIStandardName
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.IndirectEconomicImpactTable
import com.ecosave.watch.portal.models.esg.IndirectEconomicImpactsState
import com.ecosave.watch.portal.models.esg.InfrastructureInvestments
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.system.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLTextAreaElement

val IndirectEconomicImpactsComponent = FC<EsgCoreComponentProps> { props ->

    val reportState = props.reportState
    val setReportState = props.setReportState
    val griStandard = EsgSection.INDIRECT_ECONOMIC_IMPACTS

    reportState.report?.indirectEconomicImpacts?.let { state ->
        fun stateSetter(state: IndirectEconomicImpactsState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        indirectEconomicImpacts = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = griStandard.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        IndirectEconomicImpactsState::infrastureInvestmentList.name -> {
                            newState.infrastureInvestmentList.add(InfrastructureInvestments())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)
                        }

                        IndirectEconomicImpactsState::indirectEconomicImpactList.name -> {
                            newState.indirectEconomicImpactList.add(IndirectEconomicImpactTable())
                            props.setNotificationMessage(ReportConstants.ADD_SECTION_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = griStandard,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        state::infrastureInvestmentList.name -> {
                            newState.infrastureInvestmentList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }

                        state::indirectEconomicImpactList.name -> {
                            newState.indirectEconomicImpactList.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_SECTION_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)
                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == state::infrastureInvestmentList.name && changeIndex != null) {
                newState.infrastureInvestmentList[changeIndex].let {
                    when (propertyName) {
                        it::section2031Part1A1.name -> it.section2031Part1A1 = propertyValue
                        it::section2031Part1A2.name -> it.section2031Part1A2 = propertyValue
                        it::section2031Part1A3.name -> it.section2031Part1A3 = propertyValue
                        it::section2031Part1A4.name -> it.section2031Part1A4 = propertyValue
                    }
                }
            } else if (field == state::indirectEconomicImpactList.name && changeIndex != null) {
                newState.indirectEconomicImpactList[changeIndex].let {
                    when (propertyName) {
                        it::section2032A1.name -> it.section2032A1 = propertyValue
                        it::section2032B1.name -> it.section2032B1 = propertyValue
                        it::section2032C1.name -> it.section2032C1 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section3_3_203A1.name -> newState.section3_3_203A1 = propertyValue
                    state::section3_3_203A2.name -> newState.section3_3_203A2 = propertyValue
                    state::section3_3_203A3.name -> newState.section3_3_203A3 = propertyValue
                    state::section3_3_203A4.name -> newState.section3_3_203A4 = propertyValue
                    state::section3_3_203A5.name -> newState.section3_3_203A5 = propertyValue
                    state::section3_3_203A6.name -> newState.section3_3_203A6 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = griStandard.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value
            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                griStandard.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GRIStandardName {
                this.griStandard = griStandard
            }

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = griStandard.description

                this.section3_3_A1_name = state::section3_3_203A1.name
                this.section3_3_A1_value = state.section3_3_203A1

                this.section3_3_A2_name = state::section3_3_203A2.name
                this.section3_3_A2_value = state.section3_3_203A2

                this.section3_3_A3_name = state::section3_3_203A3.name
                this.section3_3_A3_value = state.section3_3_203A3

                this.section3_3_A4_name = state::section3_3_203A4.name
                this.section3_3_A4_value = state.section3_3_203A4

                this.section3_3_A5_name = state::section3_3_203A5.name
                this.section3_3_A5_value = state.section3_3_203A5

                this.section3_3_A6_name = state::section3_3_203A6.name
                this.section3_3_A6_value = state.section3_3_203A6
            }

            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_203_1)) {
                Disclosure_203_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_203_2)) {
                Disclosure_203_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
        }

    }
}