package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.esg.EsgPages
import com.ecosave.watch.portal.helpers.esg.ReportSummaryEnums
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.pages.mainScope
import com.ecosave.watch.portal.services.esg.deleteReport
import com.ecosave.watch.portal.services.esg.getAllReports
import com.ecosave.watch.portal.styles.CommonStyles
import com.ecosave.watch.portal.styles.esg.ESGFormStyles
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Upload
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.useEffectOnce
import react.useState
import web.cssom.FontWeight
import web.cssom.px

external interface EsgReportsComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var visiblePage: EsgPages
    var setVisiblePage: StateSetter<EsgPages>
}

val EsgReportsComponent = FC<EsgReportsComponentProps> { props ->

    val (reportsList, setReportsList) = useState<List<EsgReportState>>(emptyList())
    var isLoading by useState(true)
    val (notificationState, setNotificationState) = useState(NotificationState())
    val (toBeDeletedReportFileName, setToBeDeletedReportFileName) = useState("")
    val (openDeleteReportConfirmationDialog, setOpenDeleteReportConfirmationDialog) = useState(false)
    var helperMessage by useState("")
    var isDeletingReport by useState(false)
    val (showUploadReportCoverImageDialog, setShowUploadReportCoverImageDialog) = useState(false)
    val globalState = useGlobalState()

    useEffectOnce {
        props.setReportState(EsgReportState())
        globalState.updatePageTitle(PageTitles.ESG_REPORTING.title)

        mainScope.launch {
            isLoading = true
            val reports = getAllReports()
            if (reports == null) {
                helperMessage = "There is an error fetching Esg Reports, please retry."
            } else if (reports.isEmpty()) {
                helperMessage = "No reports found."
            } else {
                setReportsList(reports)
            }
            isLoading = false
        }
    }
    Box {
        Box {
            sx {
                marginBottom = 20.px
            }
            Button {
                variant = ButtonVariant.contained
                className = ESGFormStyles.ADD_BUTTON.cssClass
                startIcon = startIcon.also {
                    Add {
                        sx {
                            marginRight = 5.px
                        }
                    }
                }
                onClick = {
                    props.setVisiblePage(EsgPages.CREATE_REPORT)
                }
                +"Create a Report"
            }
            Button {
                variant = ButtonVariant.contained
                className = ESGFormStyles.ADD_BUTTON.cssClass
                startIcon = startIcon.also {
                    Upload {
                        sx {
                            marginRight = 5.px
                        }
                    }
                }
                onClick = {
                    setShowUploadReportCoverImageDialog(true)
                }
                +"Update Report Cover Page"
            }
        }
        Box {
            TableContainer {
                className = CommonStyles.TABLE_HEIGHT.cssClass
                Table {
                    stickyHeader = true
                    TableHead {
                        TableRow {
                            for (reportData in ReportSummaryEnums.entries) {
                                TableCell {
                                    sx {
                                        fontWeight = FontWeight.bold
                                    }
                                    align = TableCellAlign.left
                                    +reportData.description
                                }
                            }
                        }
                    }
                    TableBody {
                        if (isLoading || reportsList.isEmpty()) {
                            TableRow {
                                TableCell {
                                    colSpan = 8
                                    align = TableCellAlign.center
                                    if (isLoading) +"Fetching reports..." else +helperMessage
                                }
                            }
                        } else {
                            for (report in reportsList) {
                                EsgReportsTableRowComponent {
                                    visiblePage = props.visiblePage
                                    setVisiblePage = props.setVisiblePage
                                    this.report = report
                                    setReportState = props.setReportState
                                    this.setToBeDeletedReportFileName = setToBeDeletedReportFileName
                                    this.setOpenDeleteReportConfirmationDialog = setOpenDeleteReportConfirmationDialog
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
    ConfirmationDialog {
        open = openDeleteReportConfirmationDialog
        title = "Delete Report"
        body = "Are you sure you want to delete this report?"
        actionResult = { result ->
            if (result) {
                mainScope.launch {
                    isDeletingReport = true
                    val status = deleteReport(toBeDeletedReportFileName)

                    if (status == ApiCallStatus.SUCCESS) {
                        setReportsList { list ->
                            list.filter { it.reportFileName != toBeDeletedReportFileName }
                        }
                        setNotificationState(
                            notificationState.copy(
                                status = NotificationStatus.SUCCESS,
                                message = "Reported has been deleted successfully.",
                                visible = true
                            )
                        )
                    } else {
                        setNotificationState(
                            notificationState.copy(
                                status = NotificationStatus.ERROR,
                                message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                visible = true
                            )
                        )
                    }
                    setToBeDeletedReportFileName("")
                    isDeletingReport = false
                }
            }
            setOpenDeleteReportConfirmationDialog(false)
        }
    }
    DialogSpinner {
        open = isDeletingReport
        message = "Deleting report..."
    }
    UploadReportCoverImageComponent {
        this.showUploadReportCoverImageDialog = showUploadReportCoverImageDialog
        this.setShowUploadReportCoverImageDialog = setShowUploadReportCoverImageDialog
    }
}