package com.ecosave.watch.portal.components.esg.localcommunity

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.ReportConstants.TABLE_TEXT_AREA_ROW_LENGTH
import com.ecosave.watch.portal.models.esg.ImpactOnLocalCommunity
import com.ecosave.watch.portal.models.esg.LocalCommunityState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_413_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.localCommunity?.let { state ->
        val impactOnLocalCommunityListName = LocalCommunityState::impactOnLocalCommunityList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_413_2
        }
        DisclosureSubHeading {
            subHeading =
                "Operations with significant actual and potential negative impacts on local communities"
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(impactOnLocalCommunityListName)
                }
                items = state.impactOnLocalCommunityList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "The location of operation"
                    }
                    TableHeader {
                        header = "The significant actual and potential negative impacts of operation"
                    }
                }
            }
            state.impactOnLocalCommunityList.forEachIndexed { index, item ->
                DynamicTableWrapper {
                    key = item.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(impactOnLocalCommunityListName, index)
                    }
                    items = state.impactOnLocalCommunityList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.TWO_COLUMN_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = ImpactOnLocalCommunity::section4132A1.name
                            value = item.section4132A1
                            onChange = { event -> onChangeTextArea(event, index, impactOnLocalCommunityListName) }
                            onBlur = { event -> onBlurTextArea(event, index, impactOnLocalCommunityListName) }
                            minimumRows = TABLE_TEXT_AREA_ROW_LENGTH
                            maximumRows = TABLE_TEXT_AREA_ROW_LENGTH
                        }
                        ESGTextAreaFieldWrapper {
                            name = ImpactOnLocalCommunity::section4132A2.name
                            value = item.section4132A2
                            onChange = { event ->
                                onChangeTextArea(event, index, impactOnLocalCommunityListName)
                            }
                            onBlur = { event ->
                                onBlurTextArea(event, index, impactOnLocalCommunityListName)
                            }
                            minimumRows = TABLE_TEXT_AREA_ROW_LENGTH
                            maximumRows = TABLE_TEXT_AREA_ROW_LENGTH
                        }
                    }
                }
            }
        }
    }
}