package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.useGlobalState
import mui.icons.material.ExpandMore
import mui.material.Accordion
import mui.material.AccordionDetails
import mui.material.AccordionSummary
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.create
import react.useEffectOnce
import web.cssom.px
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface TableOfContentsComponentProps : Props {
    var reportState: EsgReportState
    var setReportState: StateSetter<EsgReportState>
    var tocClickedSection: (TOCSectionsEnum) -> Unit
}

val TableOfContentsComponent = FC<TableOfContentsComponentProps> { props ->

    val globalState = useGlobalState()

    useEffectOnce {
        globalState.updatePageTitle("Table Of Contents for - ${props.reportState.reportFileName}")
    }

    props.reportState.mainTableOfContents?.enrichedTableOfContents?.forEach { enrichedTableOfContent ->
        Accordion {
            AccordionSummary {
                expandIcon = ExpandMore.create {}
                Typography {
                    sx {
                        marginTop = 10.px
                        if (enrichedTableOfContent.mainTitleStatusTracker.esgSection == EsgSection.GENERAL_DISCLOSURES) {
                            paddingLeft = 20.px
                        }
                    }
                    variant = TypographyVariant.h6
                    if (enrichedTableOfContent.mainTitleStatusTracker.esgSection != EsgSection.GENERAL_DISCLOSURES) {
                        className = styles.TOC_ICON_SECOND.cssClass
                        onClick = {
                            props.tocClickedSection(TOCSectionsEnum.valueOf(enrichedTableOfContent.mainTitleStatusTracker.esgSection.name))
                        }
                    }
                    +enrichedTableOfContent.mainTitleStatusTracker.esgSection.description
                }
            }
            enrichedTableOfContent.enrichedSubContents.forEach { enrichedSubContent ->
                if (enrichedSubContent.subTitleStatusTracker?.subSection != null) {
                    AccordionSummary {
                        Typography {
                            sx {
                                marginTop = 10.px
                            }
                            className = styles.TOC_ICON_SECOND.cssClass
                            variant = TypographyVariant.h6
                            onClick = {
                                props.tocClickedSection(TOCSectionsEnum.valueOf(enrichedSubContent.subTitleStatusTracker.subSection))
                            }
                            +enrichedSubContent.subTitleStatusTracker.subTitle
                        }
                    }
                    AccordionDetails {
                        sx {
                            paddingLeft = 50.px
                        }
                        enrichedSubContent.itemStatusTrackerList.forEach { itemStatusTracker ->
                            TableOfContentsDisclosureComponent {
                                reportState = props.reportState
                                setReportState = props.setReportState
                                this.itemStatusTracker = itemStatusTracker
                            }
                        }
                    }
                } else {
                    AccordionDetails {
                        sx {
                            paddingLeft = 50.px
                        }
                        enrichedSubContent.itemStatusTrackerList.forEach { itemStatusTracker ->
                            TableOfContentsDisclosureComponent {
                                reportState = props.reportState
                                setReportState = props.setReportState
                                this.itemStatusTracker = itemStatusTracker
                            }
                        }
                    }
                }
            }
        }
    }
}