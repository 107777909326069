package com.ecosave.watch.portal.components.esg.tax

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange
import web.cssom.px

val Disclosure_207_4 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.tax?.let { state ->

        val taxJurisdictionListName = state::taxJurisdictionList.name

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_207_4
        }

        Box {
            label {
                +"All tax jurisdictions where the entities included in the organization’s audited consolidated financial statements, or in the financial information filed on public record, are resident for tax purposes."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2074A1.name
                value = state.section2074A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass
            Box {
                className = ESGCommonStyles.STICKY_HEADER.cssClass
                DynamicTableWrapper {
                    addRowOrSection = { props.addRowOrSection(taxJurisdictionListName) }
                    items = state.taxJurisdictionList
                    action = DynamicTableAction.ADD
                    variant = DynamicTableVariant.TABLE
                    addRowOrSectionBtnText = "Add details section"
                    Box {
                        sx {
                            padding = 20.px
                        }
                        DisclosureSubHeading {
                            subHeading = "For each tax jurisdiction listed above, report the following"
                        }
                    }
                }
            }

            state.taxJurisdictionList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(taxJurisdictionListName, index)
                    }
                    items = state.taxJurisdictionList
                    action = DynamicTableAction.DELETE
                    Box {
                        className = ESGCommonStyles.DYNAMIC_TABLE_SECTION_LAYOUT.cssClass
                        DisclosureSubHeading {
                            subHeading = "Tax Jurisdiction ${index + 1}"
                        }
                        Box {
                            label {
                                +"Tax Jurisdiction."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A1.name
                                value = it.section2074Part2A1
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Names of the resident entities."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A2.name
                                value = it.section2074Part2A2
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Primary activities of the organization."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A3.name
                                value = it.section2074Part2A3
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Number of employees, and the basis of calculation of this number."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A4.name
                                value = it.section2074Part2A4
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Revenues from third-party sales."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A5.name
                                value = it.section2074Part2A5
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Revenues from intra-group transactions with other tax jurisdictions."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A6.name
                                value = it.section2074Part2A6
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Profit/loss before tax."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A7.name
                                value = it.section2074Part2A7
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Tangible assets other than cash and cash equivalents."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A8.name
                                value = it.section2074Part2A8
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Corporate income tax paid on a cash basis."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A9.name
                                value = it.section2074Part2A9
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Corporate income tax accrued on profit/loss."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A10.name
                                value = it.section2074Part2A10
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                        Box {
                            label {
                                +"Reasons for the difference between corporate income tax accrued on profit/loss"
                                +"and the tax due if the statutory tax rate is applied to profit/loss before tax."
                            }
                            ESGTextAreaFieldWrapper {
                                name = it::section2074Part2A11.name
                                value = it.section2074Part2A11
                                onChange = { event -> onChangeTextArea(event, index, taxJurisdictionListName) }
                                onBlur = { event -> onBlurTextArea(event, index, taxJurisdictionListName) }
                            }
                        }
                    }
                }
            }

            Box {
                label {
                    +"The time period covered by the information reported in Disclosure 207-4."
                }
                ESGTextAreaFieldWrapper {
                    name = state::section2074A2.name
                    value = state.section2074A2
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
        }
    }
}