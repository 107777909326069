package com.ecosave.watch.portal.components.esg.marketpresence

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.DisclosureName
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val Disclosure_202_2 = FC<EsgSubFormComponentProps> { props ->

    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.marketPresence?.let { state ->

        DisclosureName {
            disclosure = GriDisclosureTitles.GRI_202_2
        }

        Box {
            label {
                +"Percentage of senior management at significant locations of operation that are hired from the local community."
            }
            ESGNumberFieldWrapper {
                name = state::section2022A1.name
                value = state.section2022A1 ?: ""
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"Definition used for “senior management”."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2022A2.name
                value = state.section2022A2 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"The organization’s geographical definition of ‘local’."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2022A3.name
                value = state.section2022A3 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }

        Box {
            label {
                +"The definition used for ‘significant locations of operation’."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2022A4.name
                value = state.section2022A4 ?: ""
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}